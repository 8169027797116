import { Component, OnInit, Input } from '@angular/core';

import { SyndicatedOptions } from '@app/shared/components/syndicated-layout/syndicated-options.interface';
import {
    SYNDICATED_HEADER_ANIMATIONS
} from '@app/shared/components/syndicated-layout/syndicated-aulani-header/syndicated-aulani-header.animations';

@Component({
    selector: 'dtr-hub-finder-syndicated-aulani-header',
    templateUrl: './syndicated-aulani-header.component.html',
    styleUrls: ['./syndicated-aulani-header.component.scss'],
    animations: [...SYNDICATED_HEADER_ANIMATIONS]
})

export class SyndicatedAulaniHeaderComponent implements OnInit {
    @Input() url: string;

    syndicatedHeaderUrl: string;
    defaultOptions: SyndicatedOptions;
    useCached = false;
    stateName: string;
    showLine = false;

    constructor() { }

    ngOnInit() {
        this.syndicatedHeaderUrl = this.url;
        this.stateName = 'loaded';
    }

    headerStateDone(event: AnimationEvent) {
        this.showLine = true;
        setTimeout(() => {
            event['element'].removeAttribute('style');
        });
    }
}
