import { Component, OnInit, Input } from '@angular/core';
import * as dofConstants from './details-on-file.component.constants';
import { Reservation } from '@app/core/models/reservation.model';
import { ReservationService } from '@app/core/services/reservation.service';
import * as _ from 'lodash';
import { magicBandDscribeModule, wdw_campus } from '@app/core/constants/common-constants';


@Component({
    selector: 'dtr-hub-details-on-file',
    templateUrl: './details-on-file.component.html',
    styleUrls: ['./details-on-file.component.scss']
})
export class DetailsOnFileComponent implements OnInit {
    @Input() campus;
    @Input() dscbDetails;
    detailsOnFlData: any;
    show = false;
    isSYWSet: boolean;
    isRRNSet: boolean;
    isResortArrivalSet: boolean;
    isMagicBandSet: boolean;
    isSYWEligible: boolean;
    isPinSet: boolean;
    allSectionsOnFile: boolean;
    isHotelAccountChargesSet: boolean;
    isReservationAddressValid: boolean;
    isPetRegistrationSet: boolean;
    reservationWithPreferences: Reservation;
    constructor(public reservationService: ReservationService) { }

    private _reservation: Reservation;
    @Input() set reservationResponse(value) {
        this._reservation = value;
        this.setDetailsOnFile();
    }

    ngOnInit() {
        this.detailsOnFlData = _.find(this.dscbDetails, function(module) {
            return module.id === dofConstants.moduleId;
        });
    }

    get reservation(): Reservation {
        return this._reservation;
    }

    setDetailsOnFile() {
        this.allSectionsOnFile = false;
        this.isSYWEligible = this.reservationService.isSYWEligible(this.reservation);
        this.isSYWSet = this.reservationService.isSYWSet(this.reservation);
        this.isRRNSet = this.reservationService.isRRNSet(this.reservation);
        this.isResortArrivalSet = this.reservationService.isResortArrivalSet(this.reservation);
        this.isMagicBandSet = this.campus === wdw_campus ? this.reservationService.isMagicBandSet(this.reservation) : false;
        this.isPinSet = this.reservationService.isPinSet(this.reservation);
        this.isHotelAccountChargesSet = this.reservationService.isHotelAccountChargesSet(this.reservation);
        this.isReservationAddressValid = this.reservationService.isReservationAddressValid(this.reservation);
        this.isPetRegistrationSet = this.reservationService.isPetRegistrationSet(this.reservation);

        if ((!this.isSYWEligible || this.isSYWSet) && this.isRRNSet && this.isResortArrivalSet && this.isPinSet
            && this.isHotelAccountChargesSet && this.isReservationAddressValid && this.isPetRegistrationSet) {
            this.allSectionsOnFile = true;
        }
    }

    toggleShowDiv() {
        this.show = !this.show;
        return false;
    }

    showHubRoomLocationRequest(): boolean {
        // Due to an issue with not properly trasnforming inbound data into the defined types,
        // we need to do something kinda hacky here..
        const accommodations = this._reservation &&
            this._reservation.travelPlanSegmentDetails &&
            this._reservation.travelPlanSegmentDetails['accommodation'];

        return accommodations &&
            accommodations.length > 0 &&
            accommodations[0].profileInfos &&
            accommodations[0].profileInfos.length > 0;
    }

    showMagicBandComponent(): boolean {
        return this._reservation &&
            this.isMagicBandSet &&
            this.campus === wdw_campus &&
            this.dscbDetails.some(module => module.id === magicBandDscribeModule);
    }

    shouldResortArrivalSectionDisplay(): boolean {
        return !this.reservationService.getShouldHideEta(this._reservation) &&
            this._reservation && this.isResortArrivalSet;
    }
    
    showPetRegistrationComponent(): boolean {
        return this.campus === wdw_campus && this.isPetRegistrationSet && this.reservation && this.reservation.preferences &&
            this.reservation.preferences.petInfo && this.reservation.preferences.petInfo.petCount > 0;
    }
}
