import { trigger, transition, state, style, animate } from '@angular/animations';

export const SYNDICATED_FOOTER_ANIMATIONS = [
    trigger('footerState', [
        state('*', style({
            opacity: 0,
            transform: 'translateY(70px)'
        })),
        state('loaded', style({
            opacity: 1,
            transform: 'translateY(0)'
        })),
        transition('* => loaded', animate('500ms ease-out'))
    ])
];
