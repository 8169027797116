import { Injectable } from '@angular/core';
import { GlobalThemeService } from '@app/services/global-theme-service/global.theme.service';
import { Router } from '@angular/router';
import { ConfigService } from '@core/config.service';
import { AuthService } from '@core/services/auth/auth.service';

@Injectable()
export class RoutingService {
    constructor(
        public globalThemeService: GlobalThemeService,
        public router: Router,
        public configService: ConfigService,
        public authService: AuthService
    ) {}

    routeToRouteURL(routeURL: string, openInNewTab: boolean = false) {
        const queryParamsMap: any = {};
        queryParamsMap.returnUrl = this.globalThemeService.getReturnURL();

        if (this.globalThemeService.getCampusParam()) {
            queryParamsMap.campus = this.globalThemeService.getCampusParam();
        }

        if (openInNewTab) {
            routeURL =
                routeURL +
                '?' +
                Object.keys(queryParamsMap)
                    .map(key => key + '=' + queryParamsMap[key])
                    .join('&');
            this.globalThemeService.getWindow().open(routeURL, '_blank');
        } else {
            this.router.navigate([routeURL], { queryParams: queryParamsMap });
        }
    }

    //This is used by the APP DVIC banner to navigate to the Disney Visa CC application
    // During OLCI. Most other routing should happen with the above routeToRouteURL
    routeToExternalURL(routeUrl: string) {
        this.globalThemeService.getDocument().location.href = routeUrl;
    }

    redirectToReturnURL() {
        const hostName =
            this.globalThemeService.getHostName() === 'localhost'
                ? 'latest.disneyworld.disney.go.com'
                : this.globalThemeService.getHostName();
        const redirectURL = 'https://' + hostName + this.globalThemeService.getReturnURL();
        this.globalThemeService.getWindow().location.href = redirectURL;
    }

    redirectToHomeURL() {
        const hostName =
            this.globalThemeService.getHostName() === 'localhost'
                ? 'latest.disneyworld.disney.go.com'
                : this.globalThemeService.getHostName();
        const redirectURL = 'https://' + hostName;
        this.globalThemeService.getWindow().location.href = redirectURL;
    }

    redirectToLoginScreen() {
        const campus: string = this.globalThemeService.getCampusBasedOnHost();
        if (campus!='wdw') {
          this.redirectToHomeURL();
        } else {
          location.reload();
        }
    }
}
