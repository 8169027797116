import { Component, ElementRef, Input, OnInit, OnDestroy, Inject, HostBinding } from '@angular/core';

import { ModalService } from '../../services/modal/index';
import { DOCUMENT } from '@angular/common';
import { GlobalThemeService } from '@app/services/global-theme-service/global.theme.service';

@Component({
    selector: 'dtr-hub-modal',
    template: '<ng-content></ng-content>'
})

export class ModalComponent implements OnInit, OnDestroy {
    @Input() id: string;
    @HostBinding('class.aulani') aulani: boolean = this.globalThemeService.getCampus() === 'aulani';
    @HostBinding('class.wdw') wdw: boolean = this.globalThemeService.getCampus() === 'wdw';

    constructor(private modalService: ModalService, private elementRef: ElementRef,
                @Inject(DOCUMENT) private document: Document,
                private globalThemeService: GlobalThemeService) {
    }

    ngOnInit(): void {
        const modal = this;

        // ensure id attribute exists
        // if (!this.id) {
        //     console.error('modal must have an id');
        //     return;
        // }
        // TODO: to uncomment above
        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        this
        .document
         .querySelector('body')
         .appendChild(this.elementRef.nativeElement);

        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
    }

    // remove self from modal service when directive is destroyed
    ngOnDestroy(): void {
        this.modalService.remove(this.id);
        this.elementRef.nativeElement.remove();
    }

    // open modal
    open(): void {
        this.elementRef.nativeElement.style.display = 'block';
        this.document.body.classList.add('modal-open');
    }

    // close modal
    close(): void {
        this.elementRef.nativeElement.style.display = 'none';
        this.document.body.classList.remove('modal-open');
    }
}
