export const raHeading = 'Reservation Address';
export const raHeadingAlt = 'Reservation Address, ';
export const raEdit = 'Edit';
export const raEditAlt = 'Edit';
export const raModalHeadingAlt = 'Update Your Address';
export const raDescription = 'You may update the address we have on file';
export const raCountrySelectFieldName = 'Country/Region';
export const raRequiredText = 'required';
export const raDropdownText = 'dropdown';
export const raAddressLineOneLabel = 'Address Line 1';
export const raAddressLineTwoLabel = 'Address Line 2';
export const raCityLabel = 'City';
export const raTextFieldText = 'textfield';
export const raSaveAddressToProfileHeadingText = 'Save this address to my profile';
export const raSaveAddressToProfileSubText = 'Making this selection will also replace the address currently stored in your profile';
export const updateText = 'Update';
export const doneText = 'Done';
export const buttonText = 'Button';
export const cancelText = 'Cancel';
export const raRequestFieldText = '*required field';
export const raTextSelect = 'Select';
export const moduleId = 'reservationAddress';
export const raModalId = 'reservationAddressModal';
