import { Component, Input, OnInit } from '@angular/core';
import { PrearrivalInfo } from '@app/core/models/pre-arrival-info.model';
import { ServiceYourWayDetails } from '@app/core/models/service-your-way.model';
import * as _ from 'lodash';
import * as UI_CONSTANTS from '@app/service-your-way/service-your-way.constants';
import {ReservationService} from '@core/services/reservation.service';
import { GlobalThemeService } from '@app/services/global-theme-service/global.theme.service';
import { commonId } from 'app/core/constants/common-constants';

@Component({
  selector: 'dtr-hub-service-your-way',
  templateUrl: './service-your-way.component.html',
  styleUrls: ['./service-your-way.component.scss']
})
export class ServiceYourWayComponent implements OnInit {
    @Input() collapsed;
    @Input() isAddFlow;
    edit = false;
    readMoreExpanded = false;
    private _preArrivalInfo: PrearrivalInfo;
    private _serviceYourWayDetails: ServiceYourWayDetails;
    private UI_CONSTANTS;
    radioOption = 'no';
    @Input() dscbDetails;
    servYouWayData: any;
    commonData: any;

    @Input() set preArrivalInfo(value) {
        this._preArrivalInfo = value;
    }

    @Input() set serviceYourWayDetails(value) {
        this._serviceYourWayDetails = value;
    }

    _sywOpted: boolean;
    _sywOptedStatus: boolean;

    constructor(public globalThemeService: GlobalThemeService,
                public reservationService: ReservationService) { }

    ngOnInit() {
        this._sywOpted = this._preArrivalInfo.serviceYourWayOptIn !== null ? true : false;
        this._sywOptedStatus = this._preArrivalInfo.serviceYourWayOptIn !== null ?
            this._preArrivalInfo.serviceYourWayOptIn : false;
        this.UI_CONSTANTS =  UI_CONSTANTS;
         this.servYouWayData = _.find(this.dscbDetails, function(module) {
            return module.id === UI_CONSTANTS.moduleId;
        });
        this.radioOption = this._sywOptedStatus ? 'yes'  : 'no';
        this.reservationService.roomcheckinPayload.prearrivalInfo.serviceYourWayOptIn = this._sywOptedStatus;
        this.commonData = _.find(this.dscbDetails, function(module) {
            return module.id === commonId;
        });
    }

    get sywOpted(): boolean {
        return this._sywOpted;
    }

    set sywOpted(value: boolean) {
        this._sywOpted = value;
    }

    get sywOptedStatus(): boolean {
        return this._sywOptedStatus;
    }

    get serviceYourWayDetails() {
        return this._serviceYourWayDetails;
    }

    getNoRadioAriaLabel() {
        let finalText = '';
        const radioStatus = this.radioOption === 'yes' ?
        this.servYouWayData.descriptions[0].sections.unselectedText : this.servYouWayData.descriptions[0].sections.selectedText;
         const readCopy = this.servYouWayData.descriptions[0].sections.no.heading + '.' +
         this.servYouWayData.descriptions[0].sections.no.description;
        finalText += radioStatus + ', ' + readCopy + ', ' + this.servYouWayData.descriptions[0].sections.no.index
        + ', ' + this.servYouWayData.descriptions[0].sections.radioButtonText;
        return finalText;
    }

    getYesRadioAriaLabel() {
        let finalText = '';
        const radioStatus = this.radioOption === 'no' ?
        this.servYouWayData.descriptions[0].sections.unselectedText : this.servYouWayData.descriptions[0].sections.selectedText;
         const readCopy = this.servYouWayData.descriptions[0].sections.yes.heading + '.' +
         this.getYesRadioDescription();
        finalText += radioStatus + ', ' +  readCopy + ', ' + this.servYouWayData.descriptions[0].sections.yes.index
        + ', ' + this.servYouWayData.descriptions[0].sections.radioButtonText;
        return finalText;
    }

    getYesRadioDescription() {
        return this.servYouWayData.descriptions[0].sections.yes.description.replace('<<>>',
          this.serviceYourWayDetails.amount.amountValue.toString());
    }

    onRadioChange(e) {
        this.radioOption = e.detail.value;
        this.reservationService.roomcheckinPayload.prearrivalInfo.serviceYourWayOptIn = e.detail.value === 'yes';
    }

    getTncBody() {
        if (this.serviceYourWayDetails) {
            return this.serviceYourWayDetails.additionalInfo.conditions;
        }
    }
}
