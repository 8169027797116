export const serviceName = 'DTRWebAPI';

export const moment = {
    format: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
    unixTimeStamp: 'x',
    dateTimeFormat: 'YYYY-MM-DDTHH:mm:ssZZ',
    dateFormat: 'YYYY-MM-DD',
    timeFormat: 'HH:mm',
    timeZoneFormat: 'z',
    reservationDateFormat: 'MM/DD/YYYY',
    reservationDatesFormat: 'dddd, MMM D, YYYY'
};

export const serviceOperations = {
    getRoomcheckinData: 'Get Room checkin details',
    getCountryAndStateData: 'Get Country and State details',
    getReservationSummaryData: 'Get Reservation Summary details',
    getAvatarData: 'Get Avatar details',
    getDScribeData: 'Get DScribe content details',
    getOlciStaticData: 'Get OLCI Static content details',
    getAPPSessionData: 'Establish APP session and get session details',
    postRoomcheckinData: 'Commit Room checkin details',
    getAPPSessionDetails: 'Get APP Session Details',
    postCacheDetails: 'Commit OLCI details for cache',
    getCacheDetails: 'Get OLCI details from cache',
    delCacheDetails: 'Delete OLCI details from cache',
};

export const serviceType = {
    OUTBOUND_REQUEST: 'OUTBOUND-REQUEST',
    OUTBOUND_RESPONSE: 'OUTBOUND-RESPONSE'
};

export const routeURLs = {
    SLASH: '/',
    BASE_URL: '',
    RESORT_RESERVATION: 'resort-reservation/',
    RRN_TNC: 'room-ready-notifications-terms-and-conditions',
    ERROR: 'error'
};

export const petRouteURLs = {
    SLASH: '/',
    BASE_URL: '',
    RESORT_RESERVATION: 'resort-reservation/',
    PET_TNC: 'pet-registration-terms-and-conditions',
    ERROR: 'error'
};

export const serviceURLs = {
    AULANI_RESERVATIONS: '/my-aulani/api/v1/reservations/user/'
};

export const aulaniFacilities = {
    RESORT: 'Aulani, A Disney Resort & Spa, Ko Olina, Hawaii',
    MINICAMPUS: 'Aulani, Disney Vacation Club Villas, Ko Olina, Hawaii'
};

export const commonId = 'common';
export const olciHeaderId = 'olciHeader';
export const olciTncLocalStorageName = 'olci_tnc_body';
export const olciPetTncLocalStorageName = 'olci_pet_tnc_body';
export const olciTncStaticContentLocalStorageName = 'olci_tnc_static_content';

export const defaultLanguage = 'en_US';
export const magicBandDscribeModule = 'magicBand';
export const petDetailsDscribeModule = 'petDetails';


export const cacheSessionIdKey = 'cacheSessionId';

export const TOPRR = 'TOPRR';

export const wdw_campus = 'wdw';
export const olciPetTncTitleLocalStorageName = 'olci_pet_tnc_title';
export const olciPetTncSubTitleLocalStorageName = 'olci_pet_tnc_subTitle';
