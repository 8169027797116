export const heading = 'Set Up Resort Account PINs';
export const headingText = 'Set Up Resort Account PINs';
export const description = 'For all purchases charged to your Disney Resort hotel account,' +
                            ' you will need to enter a PIN.' +
                            ' To reset your PIN please visit your ' +
                            'Disney Resort hotel Front Desk for assistance.';
export const enterPin = 'Enter your PIN';
export const enterPinAlt = 'enterPinAlt, ';
export const pinDescription = 'Use 4 non-sequential numbers. ' +
                               'For your protection, keep your PIN confidential';
export const checkedMessage = 'Use this PIN for everyone in my travel party.';

export const managePin = 'PINs Managed by You';
export const managePinAlt = 'PINs Managed by You, ';

export const yourPin = 'Your PIN';
export const yourPinAlt = 'Your PIN, ';
export const alreadyPin = 'PIN';
export const alreadyPinText = 'Has a PIN';
export const alreadyPinSet = 'Your PIN is set';

export const update = 'Update';
export const updateAlt = 'Update, button';
export const cancel = 'Cancel';
export const cancelAlt = 'Cancel, button';

export const enterPinText = 'Enter PIN, required, value, textfield';
export const confirmPinText = 'Confirm PIN, required, value, textfield';
export const moduleId = 'resortPin';
export const addpinID = 'addPinModel';
