import { Injectable } from '@angular/core';
import { GlobalThemeService } from '@app/services/global-theme-service/global.theme.service';
import { ConfigService } from '@core/config.service';
import { LOGIN_URL } from '@app/shared/constants/app.constants';
import * as authConstants from './auth.constants';
import * as _ from 'lodash';
import {BehaviorSubject} from "rxjs";
declare let DisneyID: any;
declare let PEP: any;
@Injectable()
export class AuthService {
    private didappid: string;
    private responderPage: string;
    private campus: string;
    private config: any;

    private _accessToken: string;
    private _swid: string;

    private isInitialized$ = new BehaviorSubject<boolean>(false);

    constructor(private globalThemeService: GlobalThemeService, private configService: ConfigService) {
        this.campus = this.globalThemeService.getCampusBasedOnHost();
        this.config = _.cloneDeep(this.configService.config);
        this.didappid = this.campus === authConstants.dvc ? this.config.dvcClientId : this.config.aulaniClientId;
        this.responderPage = this.config.baseURL + 'assets/html/lightbox/' + this.config.env.toLowerCase() + '.html';
    }

    redirectToLightboxLogin(): Promise<any> {
        const that = this;
        return new Promise(resolve => {
            document.getElementsByTagName('head')[0].appendChild(this.generateLightBoxScript()).onload = () => {
                const did = DisneyID.get({
                    clientId: this.didappid,
                    responderPage: this.responderPage,
                    debug: false,
                    blueCookie: true,
                    cssOverride: 'https://secure.parksandresorts.wdpromedia.com/media/resorts/lightbox/custom-disid.css?1'
                });
                function loginSuccess() {
                    console.log('Login Successful.');
                    that.globalThemeService.getWindow().location.reload();
                }

                did.on('login', loginSuccess);

                did.init()
                    .then(function initSuccess(data) {
                        if (data.loggedIn) {
                            resolve(data);
                        } else {
                            did.launchLogin();
                        }
                    })
                    .catch(function initError(data) {
                        console.log('User not logged in');
                        did.launchLogin();
                    });
            };
        });
    }

    redirectToWDWlogin(): void {
        const hostName =
            this.globalThemeService.getHostName() === 'localhost'
                ? 'latest.disneyworld.disney.go.com'
                : this.globalThemeService.getHostName();
        let returnUrl = this.globalThemeService
            .getWindow()
            .location.href.replace(hostName, '')
            .replace('https://', '');
        if (returnUrl.includes('/error/')) {
            returnUrl = returnUrl.replace('/error/', '/resort-reservation/');
        }
        const loginStub = LOGIN_URL;
        const url = `https://${hostName}${loginStub}?returnUrl=${returnUrl}`;
        this.globalThemeService.getWindow().location.href = url;
    }

    private generateLightBoxScript(): HTMLScriptElement {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'disneyid-script';
        script.charset = 'utf-8';
        script.async = true;
        script.src = this.config.lightboxDIDURL;

        return script;
    }

  public async reAuth(): Promise<void> {
      try {
        const guest = await PEP.PROFILE_AUTH.launchReauth();
        this.setLoggedInGuestData(guest.token);
      }catch (e) {
        console.error(e.message, e);
      }
  }

  async initialize(): Promise<void> {
    await this.checkFirstLoggedInStatus();
    this.registerLoginEvents();
    this.registerPostLoggedInEvents();
  }

  logout(): any {
    return PEP.PROFILE_AUTH.logout();
  }

  private async checkFirstLoggedInStatus () {
    const loggedInStatus = await PEP.PROFILE_AUTH.getLoggedInStatus();
    if (!loggedInStatus) {
      try {
        await PEP.PROFILE_AUTH.launchLogin();
      } catch (err) {
        location.reload();
      }
    } else {
      await this.checkTrustStatus();
    }
  }

  private registerLoginEvents(): void {
    const actions = (guest) => {
      this.setLoggedInGuestData(guest.token);
      this.registerPostLoggedInEvents();
    };
    PEP.PROFILE_AUTH.on('login', (guest) =>{
      actions(guest);
    });
    PEP.PROFILE_AUTH.on('reauth', (guest) =>{
      actions(guest);
    });
  }

  private async checkTrustStatus() {
    const highTrustStatus = await PEP.PROFILE_AUTH.getTrustStateStatus();
      if (highTrustStatus) {
         await this.refresh();
      } else {
         await this.reAuth();
      }
  }

  private registerPostLoggedInEvents (): void {
    PEP.PROFILE_AUTH.on('low-trust', () =>{
      this.reAuth().then(() => {
        console.info("low-trust event on PEP");
      }).catch(()=> {
        location.reload();
      });
    });
    PEP.PROFILE_AUTH.on('refresh', (token) =>{
      this.setLoggedInGuestData(token);
    });

    //add logout,close,refresh
  }

  private async refresh(): Promise<void> {
     const guest = await PEP.PROFILE_AUTH.getGuest();
     this.setLoggedInGuestData(guest.token);
  }

  private setLoggedInGuestData (token: any) {
    this._accessToken = token.access_token;
    this._swid = token.swid;
    this.isInitialized$.next(true);
  }

  isUnauthorizedError(err): Boolean {
    const UNAUTHORIZED = 401,
      FORBIDDEN = 403;
    return err.status &&
      (err.status === UNAUTHORIZED || err.status === FORBIDDEN);
  }

  get isInitialized(): BehaviorSubject<boolean> {
    return this.isInitialized$;
  }

  get swid(): string {
    return this._swid;
  }

  get accessToken(): string {
    return this._accessToken;
  }

  set swid(newSwid) {
    this._swid = newSwid;
  }

  set accessToken(newToken) {
    this._accessToken = newToken;
  }
}
