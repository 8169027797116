
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Reservation } from '../../models/reservation.model';
import { ReservationSummary } from '../../models/reservation-summary.model';
import { CountryAndStateDetails } from '../../models/country-and-state.model';
import { SessionDetails } from '../../models/session-details.model';

import { ConfigService } from '@app/core/config.service';
import { DtrRequestService } from '@app/core/services/dtr-request/dtr-request.service';
import * as commonConstants from '@app/core/constants/common-constants';
import { GlobalThemeService } from '@app/services/global-theme-service/global.theme.service';
import moment from 'moment';
import * as _ from 'lodash';
import { HttpHeaders } from '@angular/common/http';
import { CookieServices } from '@core/services';
import {AuthService} from "@core/services/auth/auth.service";

@Injectable()
export class DataService {

    config: any;
    url: string;
    loading: boolean;
    authSvc: AuthService;

    constructor(
        private configService: ConfigService,
        private authService:AuthService,
        private dtrRequestService: DtrRequestService,
        public globalThemeService: GlobalThemeService,
        public cookieService: CookieServices,

    ) {
        this.loading = false;
        this.config = this.configService.config;
        this.url = this.config.protocol + this.globalThemeService.getHostName() + this.config.dtrWebAPIURL;
        this.authSvc = this.authService;
    }


    getRoomcheckinData(reservationNumber: number): Observable<Reservation> {
        const request = {
            method: 'GET',
            url: `${this.url}/reservations/olci-details/`,
            params: {
                reservationId: reservationNumber
            }
        };
        return this
            .dtrRequestService
            .httpRequest(request, commonConstants.serviceOperations.getRoomcheckinData).pipe(
            map((response: any) => Reservation.fromJSON(response.body)));
    }

    postRoomcheckinData(payload, paymentSheetLoaded): Observable<any> {
        const request = {
            method: 'POST',
            url: `${this.url}/reservations/olci-details/`,
            data: payload,
            params: {
                byPassCardAuth: paymentSheetLoaded
            }
        };
        return this
            .dtrRequestService
            .httpRequest(request, commonConstants.serviceOperations.postRoomcheckinData).pipe(
            map((response: any) => response.body));
    }

    getCountryAndStateData(): Observable<CountryAndStateDetails> {
        const request = {
            method: 'GET',
            url: `${this.url}/retrieve-countries-states/`
        };
        return this
            .dtrRequestService
            .httpRequest(request, commonConstants.serviceOperations.getCountryAndStateData).pipe(
            map((response: any) => CountryAndStateDetails.fromJSON(response.body)));
    }

    getReservationSummaryData(): Observable<ReservationSummary> {
        const campus = this.globalThemeService.getCampus();
        if (campus === 'wdw') {
            return this.getWDWReservationSummaryData();
        } else {
            return this.getAulaniReservationSummaryData();
        }
    }

    getWDWReservationSummaryData(): Observable<ReservationSummary> {
        const request = {
            method: 'GET',
            url: `${this.url}/guest/reservations`
        };
        return this
            .dtrRequestService
            .httpRequest(request, commonConstants.serviceOperations.getReservationSummaryData).pipe(
            map((response: any) => ReservationSummary.fromJSON(response.body)));
    }

    getAulaniReservationSummaryData(): Observable<ReservationSummary> {
        const swid = this.authSvc.swid;
        const url = `${this.config.protocol}${this.globalThemeService.getHostName()}` +
                        `${commonConstants.serviceURLs.AULANI_RESERVATIONS}${swid}/`;
        const request = {
            method: 'GET',
            url: url
        };
        return this
            .dtrRequestService
            .httpRequest(request, commonConstants.serviceOperations.getReservationSummaryData).pipe(
            map((response: any) => ReservationSummary.fromJSON(this.mapResponse(response.body))));
    }

    mapResponse(body) {
        const that = this;
        const result = {
            startItem: 0,
            itemLimit: 9999,
            itemCount: 0,
            resortReservations: []
        };
        if (body.itemsCount > 0) {
            _.forEach(body.items, function (reservation) {
                const responseReservation = {
                    reservationNumber: reservation.reservationNumber,
                    arrivalDateTime: reservation.arrivalDate,
                    departureDateTime: reservation.departureDate,
                    facilityName: that.getFacilityName(reservation.accommodations[0].room.description)
                };
                if (that.isReservationEligibleForOLCI(reservation.arrivalDate)) {
                    result.resortReservations.push(responseReservation);
                }
            });

            result.itemCount = result.resortReservations.length;

        }
        return result;
    }

    isReservationEligibleForOLCI(arrivalDate) {
        const currentDate = moment().format('YYYY-MM-DD');
        return moment(arrivalDate, 'YYYY-MM-DD')
            .isSame(moment(currentDate, 'YYYY-MM-DD')) ||
            moment(arrivalDate, 'YYYY-MM-DD')
                .isAfter(moment(currentDate, 'YYYY-MM-DD'));
    }

    getFacilityName(description) {
        return description
            .includes(commonConstants.aulaniFacilities.RESORT) ? commonConstants.aulaniFacilities.RESORT :
            commonConstants.aulaniFacilities.MINICAMPUS;
    }

    getOlciStaticContent(facilityId: number): Observable<any> {
        const request = {
            method: 'GET',
            url: `${this.url}/resorts/online-checkin-web/static-content?facilityId=${facilityId}`
        };
        return this
            .dtrRequestService
            .httpRequest(request, commonConstants.serviceOperations.getOlciStaticData);
    }

    getDScribeContent(): Observable<any> {
        const campus = this.globalThemeService.getCampus();
        const request = {
            method: 'GET',
            url: `${this.url}/retrieve-dscribe-content/${campus}/`
        };
        return this
            .dtrRequestService
            .httpRequest(request, commonConstants.serviceOperations.getDScribeData);
    }

    establishSession(appRequestData: any, appClientHeader: string): Observable<SessionDetails> {
        const request = {
            method: 'POST',
            url: `${this.url}/dtr/payment/session-token/`,
            data: appRequestData,
            headers: this.generateAPPHeaders(appClientHeader)
        };
        return this
            .dtrRequestService
            .httpRequest(request, commonConstants.serviceOperations.getAPPSessionData).pipe(
            map((response: any) => SessionDetails.fromJSON(response.body)));
    }

    getAPPSessionDetails(sessionToken: string, appClientHeader: string): Observable<any> {
        const request = {
            method: 'GET',
            url: `${this.url}/dtr/payment/session-details/${sessionToken}/`,
            headers: this.generateAPPHeaders(appClientHeader)
        };
        return this
            .dtrRequestService
            .httpRequest(request, commonConstants.serviceOperations.getAPPSessionDetails);
    }

    generateAPPHeaders = (appClientHeader: string) => new HttpHeaders({
        'x-disney-app-client-id': appClientHeader
    });

    postCacheDetails(cacheData: any, session: string, reservationNumber: number): Observable<any> {
        const request = {
            method: 'POST',
            url: `${this.url}/cache-details?cache-key=olci.session:${session}:reservationNumber:${reservationNumber}`,
            data: cacheData
        };
        return this
            .dtrRequestService
            .httpRequest(request, commonConstants.serviceOperations.postCacheDetails).pipe(
            map((response: any) => response.body));
    }

    getCacheDetails(session: string, reservationNumber: number): Observable<any> {
        const request = {
            method: 'GET',
            url: `${this.url}/cache-details?cache-key=olci.session:${session}:reservationNumber:${reservationNumber}`,
        };
        return this
            .dtrRequestService
            .httpRequest(request, commonConstants.serviceOperations.getCacheDetails).pipe(
            map((response: any) => response.body));
    }

    delCacheDetails(session: string, reservationNumber: number): Observable<any> {
        const request = {
            method: 'DELETE',
            url: `${this.url}/cache-details?cache-key=olci.session:${session}:reservationNumber:${reservationNumber}`,
        };
        return this
            .dtrRequestService
            .httpRequest(request, commonConstants.serviceOperations.delCacheDetails).pipe(
            map((response: any) => response));
    }
}
