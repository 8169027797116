import {Component, OnInit, Input, OnDestroy} from '@angular/core';

@Component({
    selector: 'dtr-hub-finder-script-loader',
    template: ''
})
export class ScriptLoaderComponent implements OnInit, OnDestroy {
    @Input() src: string;
    script;
    constructor() { }

    ngOnInit() {
        if (this.src) {
            this.loadScript(this.src);
        }
    }

    loadScript(src: string): void {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        script.async = true;
        this.script = script;
        document.getElementsByTagName('head')[0].appendChild(script);
    }

  ngOnDestroy(): void {
      console.log('In ScriptLoaderComponent onDestroy method');
      this.script.parentNode.removeChild(this.script);
  }
}
