import { Component, Input, OnInit } from '@angular/core';
import get from 'lodash-es/get';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { SYNDICATED_HEADER_ANIMATIONS } from '@app/shared/components/syndicated-layout/syndicated-header/syndicated-header.animations';
import { WindowRef } from '@app/shared/services/window-ref/window-ref.service';
import * as _ from 'lodash';

@Component({
    selector: 'dtr-hub-finder-syndicated-header',
    templateUrl: './syndicated-header.component.html',
    styleUrls: ['./syndicated-header.component.scss'],
    animations: [...SYNDICATED_HEADER_ANIMATIONS]
})
export class SyndicatedHeaderComponent implements OnInit {
    @Input() url: string;

    syndicatedHeaderUrl: string;
    stateName: string;

    constructor(
        private winRef: WindowRef
    ) { }

    ngOnInit() {
        this.syndicatedHeaderUrl = this.url;
        this.stateName = 'loaded';
        this.setupSyndicatedToggles();
    }

    headerStateDone(event: AnimationEvent) {
        // We want to make sure that the animation is complete and the style has been set
        // by the animation module. Once that is done we can manually remove the style attr.
        setTimeout(() => {
            event['element'].removeAttribute('style');
            if (document.getElementById('globalSearchQuery')) {
                document.getElementById('globalSearchQuery').style.padding = '0 0 0 25px';
                document.getElementById('globalSearchQuery').style.height = '22px';
            }

            if (document.querySelector('.globalNavigationBar')) {
                (document.querySelector('.globalNavigationBar') as HTMLElement).style.padding = '0';
            }

            const resortBox = document.getElementById('semiDetailEmptyCartResorts');
            if (resortBox) {
                resortBox.style.width = '140px';
                (resortBox.querySelector('.continueShoppingOfferBox') as HTMLElement).style.height = '85px';
            }

            const ticketBox = document.getElementById('semiDetailEmptyCartTickets');
            if (ticketBox) {
                (ticketBox.querySelector('.continueShoppingOfferBox') as HTMLElement).style.height = '85px';
            }

            const globalNavContainer = document.querySelector('.gnbLinkContainer') as HTMLElement;
            if (globalNavContainer) {
                _.forEach(globalNavContainer.querySelectorAll('.icon__norgie-closed'), function (htmlElemnt) {
                    htmlElemnt.classList.add('di');
                    htmlElemnt.classList.add('di-norgie-closed');
                    htmlElemnt.style.fontSize = '15px';
                });
            }
            _.forEach(document.querySelectorAll('.icon__menu-global-nav'), function (htmlElemnt) {
                htmlElemnt.classList.add('di');
                htmlElemnt.classList.add('di-menu-global-nav');
                htmlElemnt.style.fontSize = '25px';
            });
            _.forEach(document.querySelectorAll('.icon__search'), function (htmlElemnt) {
                htmlElemnt.classList.add('di');
                htmlElemnt.classList.add('di-search');
                htmlElemnt.style.fontSize = '25px';
            });
            _.forEach(document.querySelectorAll('.icon__reservations'), function (htmlElemnt) {
                htmlElemnt.classList.add('di');
                htmlElemnt.classList.add('di-reservations');
                htmlElemnt.style.fontSize = '20px';
            });
            _.forEach(document.querySelectorAll('.icon__magicbands-v2'), function (htmlElemnt) {
                htmlElemnt.classList.add('di');
                htmlElemnt.classList.add('di-magicbands-original');
                htmlElemnt.style.fontSize = '20px';
            });
            _.forEach(document.querySelectorAll('.icon__friends-family'), function (htmlElemnt) {
                htmlElemnt.classList.add('di');
                htmlElemnt.classList.add('di-friends-family');
                htmlElemnt.style.fontSize = '20px';
            });
            _.forEach(document.querySelectorAll('.icon__my-media'), function (htmlElemnt) {
                htmlElemnt.classList.add('di');
                htmlElemnt.classList.add('di-my-media');
                htmlElemnt.style.fontSize = '20px';
            });
            _.forEach(document.querySelectorAll('.icon__dining'), function (htmlElemnt) {
                htmlElemnt.classList.add('di');
                htmlElemnt.classList.add('di-dining');
                htmlElemnt.style.fontSize = '20px';
            });
            _.forEach(document.querySelectorAll('.icon__fastpass-plus'), function (htmlElemnt) {
                htmlElemnt.classList.add('di');
                htmlElemnt.classList.add('di-fastpass-plus');
                htmlElemnt.style.fontSize = '20px';
            });
            _.forEach(document.querySelectorAll('.icon__memory-maker-2'), function (htmlElemnt) {
                htmlElemnt.classList.add('di');
                htmlElemnt.classList.add('di-memory-maker');
                htmlElemnt.style.fontSize = '20px';
            });
            _.forEach(document.querySelectorAll('.icon__shop-disney-parks-bag'), function (htmlElemnt) {
                htmlElemnt.classList.add('di');
                htmlElemnt.classList.add('di-shop-disney-parks-bag');
                htmlElemnt.style.fontSize = '20px';
            });
            _.forEach(document.querySelectorAll('.icon__maps'), function (htmlElemnt) {
                htmlElemnt.classList.add('di');
                htmlElemnt.classList.add('di-maps');
                htmlElemnt.style.fontSize = '15px';
            });
            _.forEach(document.querySelectorAll('.icon__next'), function (htmlElemnt) {
                htmlElemnt.classList.add('di');
                htmlElemnt.classList.add('di-next');
                htmlElemnt.style.fontSize = '20px';
            });
            _.forEach(document.querySelectorAll('.icon__calendar-month'), function (htmlElemnt) {
                htmlElemnt.classList.add('di');
                htmlElemnt.classList.add('di-calendar-month');
                htmlElemnt.style.fontSize = '20px';
            });
            _.forEach(document.querySelectorAll('.icon__magic-kingdom'), function (htmlElemnt) {
                htmlElemnt.classList.add('di');
                htmlElemnt.classList.add('di-magic-kingdom');
                htmlElemnt.style.fontSize = '20px';
            });
            _.forEach(document.querySelectorAll('.icon__epcot'), function (htmlElemnt) {
                htmlElemnt.classList.add('di');
                htmlElemnt.classList.add('di-epcot');
                htmlElemnt.style.fontSize = '20px';
            });
            _.forEach(document.querySelectorAll('.icon__hollywood-studios'), function (htmlElemnt) {
                htmlElemnt.classList.add('di');
                htmlElemnt.classList.add('di-hollywood-studios');
                htmlElemnt.style.fontSize = '20px';
            });
            _.forEach(document.querySelectorAll('.icon__animal-kingdom'), function (htmlElemnt) {
                htmlElemnt.classList.add('di');
                htmlElemnt.classList.add('di-animal-kingdom');
                htmlElemnt.style.fontSize = '20px';
            });
            _.forEach(document.querySelectorAll('.icon__registration'), function (htmlElemnt) {
                htmlElemnt.classList.add('di');
                htmlElemnt.classList.add('di-registration');
                htmlElemnt.style.fontSize = '20px';
            });
            _.forEach(document.querySelectorAll('.icon__typhoon-lagoon'), function (htmlElemnt) {
                htmlElemnt.classList.add('di');
                htmlElemnt.classList.add('di-typhoon-lagoon');
                htmlElemnt.style.fontSize = '20px';
            });
            _.forEach(document.querySelectorAll('.icon__blizzard-beach'), function (htmlElemnt) {
                htmlElemnt.classList.add('di');
                htmlElemnt.classList.add('di-blizzard-beach');
                htmlElemnt.style.fontSize = '20px';
            });
            _.forEach(document.querySelectorAll('.icon__personal-magic'), function (htmlElemnt) {
                htmlElemnt.classList.add('di');
                htmlElemnt.classList.add('di-personal-magic');
                htmlElemnt.style.fontSize = '20px';
            });
            _.forEach(document.querySelectorAll('.gnbCategoryTitle'), function (htmlElemnt) {
                htmlElemnt.style.padding = '0 9px';
            });
            if (document.querySelector('.moreParksHours')) {
                (document.querySelector('.moreParksHours') as HTMLElement).style.left = '0';
                (document.querySelector('.moreParksHours') as HTMLElement).style.marginTop = '20px';
            }

            if (document.querySelector('.hoursContainer')) {
                (document.querySelector('.hoursContainer') as HTMLElement).style.marginTop = '-20px';
            }
        });
    }

    setupSyndicatedToggles() {
        const releaseToggles = [
            'disableLocaleCookie',
            'disableLanguageSelectionCookie'
        ];

        const winRef = this.winRef.nativeWindow;
        const toggleReady: Subject<boolean> = new Subject();
        const toggleSetupInterval = setInterval(setToggles, 100);
        toggleReady
            .asObservable()
            .pipe(
                first()
            )
            .subscribe(() => {
                clearInterval(toggleSetupInterval);
            });

        function setToggles() {
            if (get(winRef, 'PEP.Config.Toggles.Release', false)) {
                releaseToggles.forEach(function (toggle) {
                    winRef.PEP.Config.Toggles.Release[toggle] = true;
                });
            }
            toggleReady.next(true);
        }
    }
}

