export const heading = 'Hotel Account Charges';
export const missingDetailsDescription = 'Set up a payment method for any balance due on ' +
                                        'arrival and incidental expenses or charges to your hotel account ' +
                                        'during your stay. This card may be charged through the end of day of check-out.';
export const detailsOnFileDescription = 'Use this card for balance due on arrival and incidental expenses.';
export const addText = 'Add';
export const replaceCardText = 'Replace Card';
export const buttonText = 'Button';
export const onFileDescription = 'Use this card for balance due on arrival and incidental expenses.';
export const moduleId = 'settlementDetails';
export const cardNameTypes = {
    'Visa': 'Visa',
    'visa': 'Visa',
    'VISA': 'Visa',
    'Master Card': 'Master Card',
    'MASTERCARD': 'Master Card',
    'MasterCard': 'Master Card',
    'Master': 'Master Card',
    'JCB': 'JCB',
    'Discover': 'Discover',
    'DISCOVER': 'Discover',
    'Diners Club': 'Diners Club',
    'DINERSCLUB': 'Diners Club',
    'DINER_CLUB': 'Diners Club',
    'American Express': 'American Express',
    'AmericanExpress': 'American Express',
    'AMERICANEXPRESS': 'American Express',
    'Amex': 'American Express',
    'AMEX': 'American Express',
    'AMX': 'American Express',
    'Disney Credit Card': 'Disney Credit Card',
    'DISNEYCREDITCARD': 'Disney Credit Card',
    'American Express Sign and Travel': 'American Express Sign and Travel',
    'AMERICANEXPRESSSIGNANDTRAVEL': 'American Express Sign and Travel',
    'Disney Rewards Card': 'Disney Rewards Card',
    'DISNEY_VISA': 'Visa'
};
