export const ebErrorHeading = 'Error';
export const ebErrorHeadingAltText = 'Error';
export const ebSorryText = 'Sorry';
export const ebErrorText = 'We encountered an error.';
export const ebTryAgainLink = 'Please try again.';
export const ebTryAgainLinkAltText = 'Please try again., link';
export const wrapperId = 'legalWrapper';
export const footerURL = '/syndicated/content/legal' +
    '?container=legalWrapper&siteOverride=wdw&responsive=1';
export const protocol = 'https://';
export const moduleId = 'errorPage';
// TODO Get this added to dscribe errorPage Module under id = "common"->descriptions->desctions
export const aulaniLogo = 'Aulani Hawaii Resort logo';

//This is used to map to specific errorPage Dscribe content based on error codes.
export const ERROR_CODE_CONTENT_MAP = {
    400100: 'checkedIn',
    0: 'olciHeader'
};

export const ERROR_CODE_IMAGE_MAP = {
    400100: 'checkin-bell',
    0: 'goofy'
};

export const errorModule = {
    'modules': [
        {
            'id': 'common',
            'sections': {
                'chromeHeader': 'Check-In',
                'aulaniLogo': 'Aulani Hawaii Resort logo'
            }
        },
        {
            'id': 'olciHeader',
            'sections': {
                'title': 'Error',
                'titleAccessibility': 'Error, Heading Level 1',
                'subHeading': 'Sorry',
                'body': 'We encountered an error.',
                'linkText': 'Please try again.',
                'linkTextAccesibility': 'Please try again, link'
            },
            'media': {
                'goofy': {
                    'id': 'goofy',
                    'type': 'png',
                    'url': 'assets/images/goofy_scratch_head.png'
                }
            }
        },
        {
            'id': 'checkedIn',
            'sections': {
                'title': '',
                'titleAccessibility': '',
                'subHeading': 'Welcome',
                'body': 'You\'ve already been checked in!',
                'linkText': 'View your plans.',
                'linkTextAccessibility': 'View your plans, link'
            },
            'media': {
                'checkin-bell': {
                    'id': 'checkin-bell',
                    'type': 'png',
                    'url': 'assets/images/CheckIn_Bell_Check in Bell-01.svg'
                }
            }
        }
    ]
};

