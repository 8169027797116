import { Component, OnInit, Input } from '@angular/core';
import { brandFooterDefaultOptions } from '@app/shared/components/syndicated-layout/syndicated-options';
import { SyndicatedOptions } from '@app/shared/components/syndicated-layout/syndicated-options.interface';
import {
    SYNDICATED_LEGAL_FOOTER_ANIMATIONS
} from '@app/shared/components/syndicated-layout/syndicated-legal-footer/syndicated-legal-footer.animations';
import * as _ from 'lodash';

@Component({
    selector: 'dtr-hub-finder-syndicated-legal-footer',
    templateUrl: './syndicated-legal-footer.component.html',
    styleUrls: ['./syndicated-legal-footer.component.scss'],
    animations: [...SYNDICATED_LEGAL_FOOTER_ANIMATIONS]
})
export class SyndicatedLegalFooterComponent implements OnInit {
    @Input() url: string;
    @Input() wrapperId: string;

    syndicatedFooterUrl: string;
    defaultOptions: SyndicatedOptions;
    useCached = false;
    stateName: string;
    showLine = false;

    constructor() { }

    ngOnInit() {
        this.defaultOptions = { ...brandFooterDefaultOptions['wdw'] };
        this.syndicatedFooterUrl = this.url;
        this.stateName = 'loaded';
    }

    footerStateDone(event: AnimationEvent) {
        this.showLine = true;
        // We want to make sure that the animation is complete and the style has been set
        // by the animation module. Once that is done we can manually remove the style attr.
        setTimeout(() => {
            event['element'].removeAttribute('style');
        });
    }
}
