import { Component, Input, OnInit } from '@angular/core';
import * as aboutOcliConstants from './about-olci.component.constants';
import { Accommodation, Period } from '@app/core/models/accommodation.model';
import { ReservationService } from '@app/core/services/reservation.service';
import { addAnalyticsTrackLinkToATag } from '@app/shared/utils/utils.object';
import { ANALYTICS, ANALYTICS_TRACK_LINKS } from '@app/shared/constants/app.constants';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'dtr-hub-about-olci',
    templateUrl: './about-olci.component.html',
    styleUrls: ['./about-olci.component.scss']
})
export class AboutOlciComponent implements OnInit {

    @Input() campus;
    @Input() allSectionsOnFile;
    @Input() showGlobalError;
    @Input() globalErrorMsg;
    @Input() dscbDetails;
    @Input() saveInProgress;
    aboutOcliData: any;
    olciData: any;

    obj: any = {};
    private _accommodations: Accommodation[];
    resortName: string;
    roomType: string;
    periodOfStay: Period[];

    private _description: string;

    @Input() set accommodations(value) {
        this._accommodations = value;
        this.setVariables();
    }

    constructor(
        private reservationService: ReservationService,
        public sanitizer: DomSanitizer
    ) { }

    ngOnInit() {
        this.aboutOcliData = _.find(this.dscbDetails, function(module) {
            return module.id === aboutOcliConstants.moduleId;
        });
        this.olciData = _.find(this.dscbDetails, function(module) {
            return module.id === aboutOcliConstants.oclimoduleId;
        });
    }

    setVariables() {
        this.resortName = this.reservationService.getResortName(this._accommodations);
        this.roomType = this.reservationService.getRoomType(this._accommodations);
        this.periodOfStay = this.reservationService.getPeriodOfStay(this._accommodations);
    }

    get getHeading() {
        return this.aboutOcliData.sections.title;
    }

    get getHeadingAltText() {
        return this.aboutOcliData.sections.titleAccesability;
    }

    get getDescription() {
        if (this._description && this._description.length > 0) {
            return this.sanitizer.bypassSecurityTrustHtml(this._description);
        }

        const content = this.aboutOcliData.sections.needMoreDetails;

        const trackLinkConfig = ANALYTICS_TRACK_LINKS.filter((conf) => {
            return conf.element === 'mdxApp';
        })[0];

        const trackLinkAddedContent = addAnalyticsTrackLinkToATag(content, trackLinkConfig, ANALYTICS);
        this._description = trackLinkAddedContent;

        return this.sanitizer.bypassSecurityTrustHtml(this._description);
    }

    get getRequiredField() {
        return this.aboutOcliData.sections.footer;
    }

    get getOLCIHeading() {
        return this.olciData.sections.title;
    }

    get getOLCIHeadingAltText() {
        return this.olciData.sections.titleAccesability;
    }

    get getAulaniDescription() {
        return this.aboutOcliData.sections.detailsOnFile;
    }
    get getDescriptionDOF() {
        return this.aboutOcliData.sections.detailsOnFile;
    }
}
