import { Directive, AfterViewInit, ElementRef, Input } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[dtrhubAutofocus]'
})
export class AutofocusDirective implements AfterViewInit {
    @Input() isPolymerInput: boolean;

    constructor(private el: ElementRef) { }

    ngAfterViewInit() {
        if (this.isPolymerInput) {
            const button = this.el.nativeElement.shadowRoot.querySelectorAll('button');
            button[0].focus();
        } else {
            this.el.nativeElement.focus();
        }
    }
}
