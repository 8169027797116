import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AutofocusDirective } from './directives/autofocus/autofocus.directive';
import { RoomLocationRequestComponent } from '@app/room-location-request/room-location-request.component';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './directives/modal';
import { ModalService } from './services/modal';
import { MaskUtil } from './utils/masking/mask-util';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        AutofocusDirective,
        ModalComponent
    ],
    exports: [
        AutofocusDirective,
        ModalComponent
    ],
    providers: [
        ModalService,
        MaskUtil
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class SharedModule {}
