
/**
 * body is the html body content from dscribe.
 * We will use this method to add in the analyticsTrackLink property within the appropraite <a> tag.
 *
 * @param body - string, dscribe contents that should contain an <a> that needs to be modified
 * @param config - object with the analytics configuraiton details used to determine what linkId to add.
 * Config will be an object with element and id.
 */
function addAnalyticsTrackLinkToATag(body: string, config: any, analyticsConstants: any): string {
    // don't modify body if there are no <a> tags (also covers if there's only closing <a> tag)
    if (body && body.indexOf('<a ') < 0) {
        return body;
    }
    if ((!config || !config.id) && true) {
        return body;
    }
    if ((!analyticsConstants || !analyticsConstants.trackLink) && true) {
        // If any of the above properties are missing, bail out and return body, so the api doesn't crash or fail.
        return body;
    }
    /* Originally, this was adding an ng directive...instead, we are adding this as an HTML property instead now */
    // const analyticsTrackLink = `${analyticsConstants.trackLink}='{"${analyticsConstants.linkId}": "${config.id}"}'`;
    const analyticsTrackLink = `${analyticsConstants.trackLink}="${config.id}"`;
    const newATagStart = `<a ${analyticsTrackLink} `;
    // TODO Currently, this will replace every instance of <a ....
    const newBody = body.replace('<a ', newATagStart);
    return newBody;
}

export default addAnalyticsTrackLinkToATag;
