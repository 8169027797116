export const heading = 'Service Your Way Program';
export const description = 'Participating in the Service Your Way program allows you ' +
                            'to decline standard daily housekeeping services in ' +
                            'exchange for a Disney Gift Card eGift. These services ' +
                            'include, but are not limited to, making beds, vacuuming, ' +
                            'tidying the bathroom area, removing trash, changing ' +
                            'linens and replenishing towels and toiletries.';
export const radioBtnDescription = {
    no: {
        heading: 'No, Don\'t Participate',
        description: 'I\'d like to keep standard daily housekeeping services',
        'index': '1 of 2'
    },
    yes: {
        heading: 'Yes, Participate',
        description: 'I’d like to decline standard daily housekeeping services ' +
        'in exchange for a $<<>> digital gift card (value is' +
        ' based on the length of your reservation)',
        'index': '2 of 2'
    }
};

export const radioButtonText = 'Radio Button';

export const selectedText = 'Selected';

export const unselectedText = 'Unselected';

export const additionalInformationText = 'Get additional details about the Service Your Way program.';

export const readMoreText = 'Read More';

export const buttonText = 'Button';

export const headingText = 'Heading';

export const EditText = 'Edit';

export const readMoreText1 = 'As a Guest who has booked 2 nights or more at ' +
'a Disney Value or Disney Moderate Resort hotel, you can choose to participate ' +
'in the Service Your Way program in exchange for a Disney Gift Card eGift that ' +
'will be emailed to the address provided in the reservation.';

export const readMoreText2 = 'The total gift card value is based on the length ' +
'of your reservation and excludes the first night of your stay, as housekeeping ' +
'services cannot be declined on the day of your arrival. The gift card value is ' +
'currently $10 per night (excluding the first night), but is subject to change.';

export const exampleHeadingText = 'Examples:';

export const nightStayGiftCardText1 = '2-Night Stay=$10 Disney Gift Card eGift';

export const nightStayGiftCardText2 = '4-Night Stay=$30 Disney Gift Card eGift';

export const nightStayGiftCardText3 = '8-Night Stay=$70 Disney Gift Card eGift';

export const nightStayGiftCardText4 = '10-Night Stay=$90 Disney Gift Card eGift';

export const readMoreText3 = 'The Disney Gift Card eGift will arrive in your email ' +
'inbox shortly after check-in, so you can start using it right away—or save it for ' +
'another time. If you don’t receive it after 4 hours, please check your spam folder ' +
'or stop by the Front Desk for assistance.';

export const learnMoreText = 'Learn More About the Service Your Way Program';

export const showLessText = 'Show Less';
// 05/29/2020 - Renaming the moduleId to 'housekeepingService' to support HK2.0
export const moduleId = 'housekeepingService';
