export const RRN_CONSTANTS = {
    rrnHeading: 'Messages During Your Vacation',
    rrnPrivacyPolicy: 'Message and data rates may apply for sending or receiving text messages.' +
        ' To stop receiving these text messages, reply STOP at anytime.',
    rrnCommunicationMessagesWDW: 'We would like to communicate with you during your Walt Disney World ' +
        'vacation to help you get the most out of your vacation.\n' +
        ' By entering your email address, mobile number or both below, you consent to ' +
        'receive communications about your vacation,\n' +
        ' Disney Resort hotel room, hotel account and Walt Disney World Resort.',
    rrnCommunicationMessagesAulani: 'We would like to communicate with you during your Aulani ' +
        'vacation to help you get the most out of your vacation.\n' +
        ' By entering your email address, mobile number or both below, you consent to ' +
        'receive communications about your vacation,\n' +
        ' Disney Resort hotel room, hotel account and Aulani Resort.',
    rrnReadMore: 'Learn about the number and types of notifications we may send you. ',
    rrnHeadingAltText: 'Messages During Your Vacation, ',
    rrnMobileAltText: 'Mobile Number, valuetextfield',
    rrnEmailAltText: 'Email, valuetextfield',
    rrnInvalidMobileText: ['Please enter a valid phone number.'],
    rrnInvalidEmailText: ['Please enter a valid email address.'],
    rrnInvalidMobileEmailText: ['Please enter a valid email address or phone number.'],
    rrnInvalidMobileAltText: 'Mobile Number, Please enter a valid phone number, valuetextfield',
    rrnInvalidEmailAltText: 'Email, Please enter a valid email number, valuetextfield',
    rrnInvalidMobileEmailAltText: 'Mobile Number, Email, Please enter a valid email address or phone number, textfield',
    rrnMobileInfoText: ['Available for U.S. phone accounts only;', 'messaging and data rates may apply.'],
    rrnAulaniMobileInfoText: ['Available for U.S. phone accounts only; messaging and data rates may apply.'],
    rrnMobileLabel: 'Mobile Number',
    rrnEmailLabel: 'Email',
    rrnPrivacyPolicyLink: 'View Our Privacy Policy',
    rrnPrivacyPolicyLinkAltText: 'View Our Privacy Policy',
    rrnReadMoreText: 'Read More',
    rrnReadMoreTextAlt: 'Read More, Button',
    rrnReadLessText: 'Read Less',
    rrnReadLessTextAlt: 'Read Less, Button',
    rrnTxtMsgsHeading: 'Text Messages',
    rrnTxtMsgsText: 'We may send you text messages to welcome you, ' +
    'provide tips, remind you of your plans and ' +
    'make suggestions intended to help you get the most ' +
    'out of your Walt Disney World experience. ' +
    'Additionally, if we identify any issues with your ' +
    'vacation—or are able to provide support orservice—we ' +
    'may attempt to proactively contact and assist you. ' +
    'The number of these types of messages you receive may vary, ' +
    'but they will not exceed 5 messages per day during your stay.',
    rrnTxtMsgsText2: 'Plus, if you send text messages to us, we may respond by text message.',
    rrnCheckinHeading: 'Hotel Check-In Emails and Text Messages',
    rrnCheckinText: 'If you arrive before check-in at your ' +
    'Disney Resort hotel room, we may send you up to 2 emails or ' +
    'text messages to notify you when your room is ready. ' +
    'If you have a MagicBand or card before you arrive, ' +
    'we may send you up to 3 additional emails or text ' +
    'messages to notify you of your room number so you can ' +
    'go straight to your room.',
    rrnSendEmailToText: 'Send email to: ',
    rrnSendTextToText: 'Send text to: ',
    rrnEdit: 'Edit',
    rrnEditAlt: 'Edit',
};
export const moduleId = 'resortCommunication';
