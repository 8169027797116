export const NATIVE_BRIDGE = {
    windowNS: 'disney',
    messagesTypes: {
        custom: 'custom',
        ready: 'ready',
        error: 'error',
        close: 'close'
    },
    commands: {
        dtrRetrieveData: 'dtr.retrieve.data',
        dtrClickedButton: 'dtr.button.clicked',
        dtrDisplayMessage: 'dtr.display.message'
    },
    callbacks: {
        dtrRetrieveDataCallback: 'dtrRetrieveDataCallback'
    },
    dtrBridgePlugin: 'dtrBridgePlugin',

    // internal - the name of the cookie to debug via desktop
    // if this cookie exists and its value is different of
    // undefined/null the native brige will attached to the callback response
    // even no comunication exists
    debugCookie: 'dtrDebugNativeApp'
};
