export const rdHeading = 'Start by Selecting a Reservation';
export const rdHeadingAltText = 'Start by Selecting a Reservation';
export const rdNextButton = 'Next';
export const rdNextButtonAltText = 'Next';
export const rdCancelButton = 'Cancel';
export const rdCancelButtonAltText = 'Cancel';
export const rdConfirmationNumberText = 'Confirmation Number: ';
export const rdThroughText = ' through ';
export const wrapperId = 'legalWrapper';
export const footerURL = '/syndicated/content/legal' +
    '?container=legalWrapper&siteOverride=wdw&responsive=1';
export const protocol = 'https://';
export const moduleId = 'selectReservation';

// Aulani Logo Alt text
// TODO Get this added to dscribe errorPage Module under id = "common"->descriptions->desctions
export const aulaniLogo = 'Aulani Hawaii Resort logo';
// Aulani timeZone to get currect Date
export const aulaniTimeZone = 'Pacific/Honolulu';
export const easternTimeZone = 'America/New_York';
