import { Component, Input, OnInit, OnDestroy, HostBinding, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Guest } from '@app/core/models/guest.model';
import { Subscription } from 'rxjs';
import { GuestReferences } from '@app/core/models/guest-references.model';
import { DataService } from '../core/services/data/data.service';
import * as _ from 'lodash';
import { ModalService } from '@app/shared/services/modal';
import * as UI_CONSTANTS from '@app/hotel-account-pin/hotel-account-pin.constants';
import { ReservationService } from '@core/services/reservation.service';
import { commonId } from 'app/core/constants/common-constants';


@Component({
    selector: 'dtr-hub-hotel-account-pin',
    templateUrl: './hotel-account-pin.component.html',
    styleUrls: ['./hotel-account-pin.component.scss']
})
export class HotelAccountPinComponent implements OnInit, OnDestroy {

    @Input() campus;
    @Input() collapsed;
    @Input() isAddFlow;
    @Input() saveInProgress;
    @Input() dscbDetails;
    private _guests: Guest[];
    private _guestReferences: GuestReferences[];
    avatarSub: Subscription;
    pinErrorMsg: string[];
    showPINError = false;

    _pinAvailable: boolean;
    guestForPINCount: number;
    showModal = false;
    primaryGuest: boolean;
    pinMissing: boolean;
    guestsForDisplay: any = [];
    isDisabled = false;
    checkBoxSelected =  false;
    showEditButton = this.reservationService.isHAPSaveSet();
    UI_CONSTANTS;

    errorResponse: any;
    error: any;
    hotelAccPInData: any;
    pinModalData: any;
    commonData: any;


    @ViewChild('pinModal') pinModal: ElementRef;

    @Input() set guests(value) {
        this._guests = value;
    }

    @Input() set guestReferences(value) {
        const guestReferences = [];
        _.forEach(value, function (accommodationDetail) {
            _.forEach(accommodationDetail.guestReferences, function (guestReference) {
                guestReferences.push(guestReference);
            });
        });
        this._guestReferences = guestReferences;
    }

    constructor(public dataService: DataService,
        public modalService: ModalService,
        private renderer: Renderer2,
        public reservationService: ReservationService) { }

    ngOnInit() {
        this.UI_CONSTANTS = UI_CONSTANTS;
        const guestsForPIN: Guest[] = _.filter(this._guestReferences, function (guest) {
            return 2 < guest.age;
        });
        const guestWithoutPIN: Guest[] = _.filter(this._guests, function (guest) {
            const findGuestForPIN: Guest[] = _.filter(guestsForPIN, function (guestForPin) {
                return guestForPin.guest.guestIdReferences.value === guest.externalReference.referenceValue;
            });
            return guest.pinAvailable === false && findGuestForPIN.length > 0;
        });
        this.hotelAccPInData = _.find(this.dscbDetails, function(module) {
            return module.id === UI_CONSTANTS.moduleId;
        });
        this.pinModalData = _.find(this.dscbDetails, function(module) {
            return module.id === UI_CONSTANTS.addpinID;
        });
        this.commonData = _.find(this.dscbDetails, function(module) {
            return module.id === commonId;
        });

        this.guestForPINCount = guestsForPIN.length;
        this._pinAvailable = guestWithoutPIN.length <= 0 && this._guests && this._guests.length > 0;
        if (this.pinAvailable) {
            this.reservationService.setHAPSaveSet(true);
            this.reservationService.roomcheckinPayload.chargeAccountDetails = null;
        } else {
            const allPinsValid: boolean = this.isPinsSetFromCache(this.reservationService.roomcheckinPayload.chargeAccountDetails);
            if (allPinsValid) {
                this.reservationService.setHAPSaveSet(true);
            } else {
                this.reservationService.setHAPSaveSet(false);
            }
            this.showPINError = true;
            this.pinErrorMsg = [this.hotelAccPInData.sections.pinNotSet];

        }

        const tempGuestList: any = [];
        const tempChargeAccountGuestList = this._guests;

        _.forEach(guestsForPIN, function (guestInfo) {

            const guestDetail = {
                primaryGuest: guestInfo.guest.primaryGuest,
                partyId: guestInfo.guest.partyId,
                guestId: guestInfo.guest.guestId,
                avatarId: guestInfo.guest.avatarId,
                avatarUrl: guestInfo.guest.avatarUrl,
                firstName: guestInfo.guest.firstName,
                lastName: guestInfo.guest.lastName,
                pinAvailable: false,
                guestIdReferences: {
                    type: guestInfo.guest.guestIdReferences.type,
                    value: guestInfo.guest.guestIdReferences.value
                },
                showError: false,
                showEnterPINError: false,
                showEnterConfirmPINError: false,
                showPINNonNumericError: false,
                showPINSeqError: false,
                showPINDiffError: false,
                enterPINValid: true,
                enterConfirmPINValid: true,
                isPINSeq: false,
                isConfirmPINSeq: false,
                isPINNonNumeric: false,
                isConfirmPINNonNumeric: false
            };
            if (guestInfo.guest.primaryGuest === true) {
                tempGuestList.unshift(guestDetail);
            } else {
                tempGuestList.push(guestDetail);
            }
        });

        this.guestsForDisplay = tempGuestList;

        _.forEach(this.guestsForDisplay, function (guest) {
            const chargeAccountDetails = _.filter(tempChargeAccountGuestList, function (chargeAcccountGuest) {
                return guest.guestIdReferences.value === chargeAcccountGuest.externalReference.referenceValue;
            })[0];
            if (chargeAccountDetails) {
                guest.firstName = chargeAccountDetails.firstName;
                guest.lastName = chargeAccountDetails.lastName;
                guest.chargeAccountId = chargeAccountDetails.chargeAccountId;
                guest.pinAvailable = chargeAccountDetails.pinAvailable;
                guest.externalReference = {
                    referenceName: chargeAccountDetails.externalReference.referenceName,
                    referenceValue: chargeAccountDetails.externalReference.referenceValue
                };
                if (!chargeAccountDetails.pinAvailable) {
                    guest.pinNumber = '';
                    guest.confirmPinNumber = '';
                }
            }
        });

        this.pinMissing = _.filter(this.guestsForDisplay, function (guest) {
            return !guest.primaryGuest && !guest.pinAvailable;
        }).length > 0;
    }

    isPinsSetFromCache(chargeAccountDetails): boolean {
        if (!chargeAccountDetails || !chargeAccountDetails.guests || chargeAccountDetails.guests.length === 0) {
            return false; // Empty object or no guests
        }
        return chargeAccountDetails.guests.every((guest) => guest.pinNumber !== null && guest.pinNumber !== '');
    }

    get pinAvailable(): boolean {
        return this._pinAvailable;
    }

    get hotelAccontPINHeading(): string {
        return this.hotelAccPInData.sections.title;
    }

    get hotelAccontPINHeadingLabel(): string {
        return this.hotelAccPInData.sections.title;

    }

    get hotelAccontPINUnavailable(): string {
        return this.hotelAccPInData.sections.body;
    }

    get hotelAccontPINAvailableText(): string {
        return this.hotelAccPInData.sections.footerEdit;
    }

    get hotelAccontPinsSet(): string {
        return `${this.hotelAccPInData.sections.bodyEdit1} ${this.guestForPINCount} ` +
            `${this.hotelAccPInData.sections.bodyEdit2} ${this.guestForPINCount}`;
    }

    get hotelAccontPINAdd(): string {
        return this.commonData.descriptions[0].sections.addLabel;
    }

    get hotelAccontPINAddText(): string {
        return this.commonData.descriptions[0].sections.addLabel;
    }

    get hotelAccontPINEdit(): string {
        return this.commonData.descriptions[0].sections.editLabel;
    }

    get hotelAccontPINEditText(): string {
        return this.commonData.descriptions[0].sections.editLabel;
    }

    get enterConfirmPINError(): string {
        return this.pinModalData.descriptions[0].sections.confirmPINError;
    }

    get enterPINError(): string {
        return this.pinModalData.descriptions[0].sections.pinError;
    }

    get nonNumericPINError(): string {
        return this.pinModalData.descriptions[0].sections.pinInvalid;
    }

    get pinSeqError(): string {
        return this.pinModalData.descriptions[0].sections.consecutiveNumber;
    }

    get mismatchPINError(): string {
        return this.pinModalData.descriptions[0].sections.pinMissmatch;
    }

    openModal() {
        this.modalService.open('pin-modal');
    }

    closeModal() {
        this.modalService.close('pin-modal');
        this.isDisabled = false;
        _.forEach(this.guestsForDisplay, function (guest) {
            guest.showError = false;
            guest.showEnterPINError = false;
            guest.showEnterConfirmPINError = false;
            guest.showPINNonNumericError = false;
            guest.showPINSeqError = false;
            guest.showPINDiffError = false;
            guest.enterPINValid = true;
            guest.enterConfirmPINValid = true;
            guest.isPINSeq = false;
            guest.isConfirmPINSeq = false;
            guest.isPINNonNumeric = false;
            guest.isConfirmPINNonNumeric = false;
        });
    }

    updatePinNumber() {
        let invalidPINExist = false;
        let index = 0;
        const that = this;
        _.forEach(this.guestsForDisplay, function validateGuestDetails(guestDetail) {
            if (guestDetail.pinAvailable) {
                index++;
                return;
            }
            guestDetail.validatePIN = true;
            guestDetail.validateConfirmPIN = true;
            if (that.isDisabled && index > 0) {
                guestDetail.pinNumber = that.guestsForDisplay[0].pinNumber;
                guestDetail.confirmPinNumber = that.guestsForDisplay[0].confirmPinNumber;
                guestDetail.showError = false;
                guestDetail.showEnterPINError = false;
                guestDetail.showEnterConfirmPINError = false;
                guestDetail.showPINNonNumericError = false;
                guestDetail.showPINSeqError = false;
                guestDetail.showPINDiffError = false;
                guestDetail.enterPINValid = true;
                guestDetail.enterConfirmPINValid = true;
                guestDetail.isPINSeq = false;
                guestDetail.isConfirmPINSeq = false;
                guestDetail.isPINNonNumeric = false;
                guestDetail.isConfirmPINNonNumeric = false;

                return;
            }
            const pinErrorExist = !that.isGuestPINValid(guestDetail, false);
            const confirmPINErrorExist = !that.isGuestConfirmPINValid(guestDetail, false);

            invalidPINExist = invalidPINExist === true ? invalidPINExist : (pinErrorExist || confirmPINErrorExist);
            index++;
        });

        if (invalidPINExist) {
            return;
        }

        const guestPinList = [];
        _.forEach(this.guestsForDisplay, function (guest) {
            if (!guest.pinAvailable) {
                const guestObj = {
                    'firstName': guest.firstName,
                    'lastName': guest.lastName,
                    'externalReference': {
                        'referenceName': guest.guestIdReferences.type,
                        'referenceValue': guest.guestIdReferences.value
                    },
                    'pinNumber': guest.pinNumber
                };
                guestPinList.push(guestObj);
            }
        });
        this.reservationService.roomcheckinPayload.chargeAccountDetails = {
            'guests': guestPinList
        };
        this.reservationService.setHAPSaveSet(true);
        this.showPINError = false;
        this.showEditButton = true;
        this.showModal = false;
        this.isDisabled = false;
        this.modalService.close('pin-modal');
    }

    onCheckBoxChanged(e) {
        const that = this;
        this.isDisabled = e.detail.value;
        const checkBoxSelected = this.isDisabled;
        let index = 0;
        _.forEach(this.guestsForDisplay, function validateGuestDetails(guestDetail) {
            guestDetail.pinNumber = that.guestsForDisplay[0].pinNumber;
            guestDetail.confirmPinNumber = that.guestsForDisplay[0].confirmPinNumber;

            if (index > 0 && checkBoxSelected === true) {
                guestDetail.showError = false;
                guestDetail.showEnterPINError = false;
                guestDetail.showEnterConfirmPINError = false;
                guestDetail.showPINNonNumericError = false;
                guestDetail.showPINSeqError = false;
                guestDetail.showPINDiffError = false;
                guestDetail.enterPINValid = true;
                guestDetail.enterConfirmPINValid = true;
                guestDetail.isPINSeq = false;
                guestDetail.isConfirmPINSeq = false;
                guestDetail.isPINNonNumeric = false;
                guestDetail.isConfirmPINNonNumeric = false;
            }
            index++;
        });
    }

    get pinForEveryoneText(): string {
        const isChecked = this.isDisabled ? this.pinModalData.descriptions[1].sections.checkboxAccessibilityChecked : this.pinModalData
                            .descriptions[1].sections.checkboxAccessibilityUnChecked;
        return isChecked + ' ' + this.pinModalData.descriptions[1].sections.checkeMessage + ', checkbox';
    }

    ngOnDestroy(): void {
        if (this.avatarSub) {
            this.avatarSub.unsubscribe();
        }
    }

    isGuestPINValid(guestDetail, skipValidation) {
        if (skipValidation) {
            return true;
        }
        let isPINEmpty = false;
        let isPINNonNumeric = false;
        let isPINNonSeq = false;
        let isPINMismatch = false;

        isPINEmpty = this.checkEmptyPIN(guestDetail.pinNumber);
        isPINNonNumeric = guestDetail.pinNumber.length > 0 ? !this.checkNumericPIN(guestDetail.pinNumber) : false;
        isPINNonSeq = guestDetail.pinNumber.length === 4 ? !this.checkSeqPIN(guestDetail.pinNumber) : false;
        isPINMismatch = guestDetail.validatePIN && guestDetail.validateConfirmPIN ? this.checkPINMismatch(guestDetail) : false;

        guestDetail.showEnterPINError = isPINEmpty;
        guestDetail.enterPINValid = !isPINEmpty && !isPINNonNumeric && !isPINMismatch && !isPINNonSeq;
        guestDetail.isPINNonNumeric = isPINNonNumeric;
        guestDetail.showPINNonNumericError = guestDetail.isPINNonNumeric || guestDetail.isConfirmPINNonNumeric;
        guestDetail.showPINDiffError = isPINMismatch;
        guestDetail.isPINSeq = isPINNonSeq;
        guestDetail.showPINSeqError = guestDetail.isPINSeq || guestDetail.isConfirmPINSeq;
        guestDetail.enterConfirmPINValid = !guestDetail.showEnterConfirmPINError && !guestDetail.isConfirmPINNonNumeric &&
            !guestDetail.isConfirmPINSeq && !isPINMismatch;

        guestDetail.showError = !guestDetail.enterPINValid || !guestDetail.enterConfirmPINValid;

        return !guestDetail.showError;
    }

    isGuestConfirmPINValid(guestDetail, skipValidation) {
        if (skipValidation) {
            return true;
        }
        let isConfirmPINEmpty = false;
        let isConfirmPINNonNumeric = false;
        let isConfirmPINNonSeq = false;
        let isPINMismatch = false;

        isConfirmPINEmpty = this.checkEmptyPIN(guestDetail.confirmPinNumber);
        isConfirmPINNonNumeric = guestDetail.confirmPinNumber.length > 0 ? !this.checkNumericPIN(guestDetail.confirmPinNumber) : false;
        isConfirmPINNonSeq = guestDetail.confirmPinNumber.length === 4 ? !this.checkSeqPIN(guestDetail.confirmPinNumber) : false;
        isPINMismatch = guestDetail.validatePIN && guestDetail.validateConfirmPIN ? this.checkPINMismatch(guestDetail) : false;

        guestDetail.showEnterConfirmPINError = isConfirmPINEmpty;
        guestDetail.enterConfirmPINValid = !isConfirmPINEmpty && !isConfirmPINNonNumeric && !isConfirmPINNonSeq && !isPINMismatch;
        guestDetail.isConfirmPINNonNumeric = isConfirmPINNonNumeric;
        guestDetail.showPINNonNumericError = guestDetail.isPINNonNumeric || guestDetail.isConfirmPINNonNumeric;
        guestDetail.showPINDiffError = isPINMismatch;
        guestDetail.isConfirmPINSeq = isConfirmPINNonSeq;
        guestDetail.showPINSeqError = guestDetail.isPINSeq || guestDetail.isConfirmPINSeq;
        guestDetail.enterPINValid = !guestDetail.showEnterPINError && !guestDetail.isPINNonNumeric &&
            !guestDetail.isPINSeq && !isPINMismatch;

        guestDetail.showError = !guestDetail.enterPINValid || !guestDetail.enterConfirmPINValid;

        return !guestDetail.showError;
    }

    checkEmptyPIN(pinNumber): boolean {
        return !(pinNumber && pinNumber.length > 0);
    }

    checkNumericPIN(pinNumber): boolean {
        const numericReg = /[^0-9]/g;
        return pinNumber.length === 4 && !numericReg.test(pinNumber);
    }

    hasSameDigits(pin): boolean {
        return (pin[0] === pin[1] && pin[0] === pin[2] && pin[0] === pin[3]);
    }

    increaseDigit(digit) {
        return (++digit % 10).toString();
    }

    isPinSequential(pin): boolean {
        return (
            this.increaseDigit(pin[0]) === pin[1] &&
            this.increaseDigit(pin[1]) === pin[2] &&
            this.increaseDigit(pin[2]) === pin[3]
        );
    }

    decreaseDigit(digit) {
        digit--;
        if (digit < 0) {
            digit += 10;
        }
        return digit.toString();
    }

    isPinReverseSequential(pin) {
        return (
            this.decreaseDigit(pin[0]) === pin[1] &&
            this.decreaseDigit(pin[1]) === pin[2] &&
            this.decreaseDigit(pin[2]) === pin[3]
        );
    }

    checkSeqPIN(pinNumber): boolean {
        if (pinNumber.length !== 4) {
            return true;
        }
        if (this.hasSameDigits(pinNumber)) {
            return false;
        }

        if (this.isPinSequential(pinNumber)) {
            return false;
        }

        if (this.isPinReverseSequential(pinNumber)) {
            return false;
        }
        return true;
    }

    checkPINMismatch(guestDetail): boolean {
        return guestDetail.pinNumber.length === 4 && guestDetail.confirmPinNumber.length === 4 &&
            guestDetail.pinNumber !== guestDetail.confirmPinNumber;
    }
}
