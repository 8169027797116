import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { GlobalThemeService } from '../services/global-theme-service/global.theme.service';
import { commonId } from 'app/core/constants/common-constants';

const defaultDscribeData = {
    descriptions: [
        {
            sections: {
                loadingLabel: 'Loading...'
            }
        }
    ]
};

@Component({
    selector: 'dtr-hub-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
    public _loaderSize: string;
    @Input() dscbDetails;
    dscribeSub: Subscription;
    errorResponse: any;
    dscribeDetails: any;
    error: any;
    campus: string;
    commonData: any;

    @Input() set loaderSize(loaderSize: string) {
        this._loaderSize = loaderSize;
    }

    constructor(public globalThemeService: GlobalThemeService) { }

    ngOnInit() {
        this.campus = this.globalThemeService.getCampus();
        if (this.dscbDetails) {
            this.commonData = _.find(this.dscbDetails, function(module) {
                return module.id === commonId;
            });
        } else {
            // Fallback on default so we still get loader...
            this.commonData = defaultDscribeData;
        }
    }
}
