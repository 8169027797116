import { Injectable, InjectionToken, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export const ConfigServiceToken = new InjectionToken('config.service');

@Injectable()
export class ConfigService {
    config: Object;

    constructor(private injector: Injector) {}

    async load(): Promise<any> {
        const http = this.injector.get(HttpClient);
        this.config = await http.get('/trip/online-check-in/config/').toPromise();
        return this.config;
    }

    getValue(key: string): any {
        // Get value of config key:
        return this.config[key];
    }
}
