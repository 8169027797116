import { SyndicatedOptions, BrandSyndicatedOptions } from '@app/shared/components/syndicated-layout/syndicated-options.interface';
import { LOCALES } from '@app/shared/constants/app.constants';

const cachedPath = '/cached/';
const defaultLocale = LOCALES.en_US;
const defaultLanguage = 'en-us';
const wdwDefaultAffiliation = 'STD_GST';
const dlrDefaultAffiliation = 'STD_GST-CAL_RESIDENT-SOCAL_RESIDENT';
const wdwSiteId = 'wdw';
const dlrSiteId = 'dlr';

// WDW
const wdwHeaderDefaultOptions: SyndicatedOptions = {
    container: 'headerWrapper',
    showSignInSignOut: 1,
    languageSelector: 1,
    responsive: 1,
    affiliation: wdwDefaultAffiliation,
    locale: defaultLocale,
    language: defaultLanguage,
    siteOverride: wdwSiteId
};

const wdwFooterDefaultOptions: SyndicatedOptions = {
    container: 'footerWrapper',
    responsive: 1,
    affiliation: wdwDefaultAffiliation,
    locale: defaultLocale,
    language: defaultLanguage,
    siteOverride: wdwSiteId
};

// DLR
const dlrHeaderDefaultOptions: SyndicatedOptions = {
    container: 'headerWrapper',
    showSignInSignOut: 1,
    languageSelector: 1,
    responsive: 1,
    affiliation: dlrDefaultAffiliation,
    locale: defaultLocale,
    language: defaultLanguage,
    siteOverride: dlrSiteId
};

const dlrFooterDefaultOptions: SyndicatedOptions = {
    container: 'footerWrapper',
    responsive: 1,
    affiliation: dlrDefaultAffiliation,
    locale: defaultLocale,
    language: defaultLanguage,
    siteOverride: dlrSiteId
};

// @TODO add more brand configs

const brandHeaderDefaultOptions: BrandSyndicatedOptions = {
    wdw: wdwHeaderDefaultOptions,
    dlr: dlrHeaderDefaultOptions
};

const brandFooterDefaultOptions: BrandSyndicatedOptions = {
    wdw: wdwFooterDefaultOptions,
    dlr: dlrFooterDefaultOptions
};

export { brandHeaderDefaultOptions, brandFooterDefaultOptions, cachedPath };
