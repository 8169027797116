import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Accommodation } from '@app/core/models/accommodation.model';
import _ from 'lodash';
import * as rlrConstants from './room-location-request.component.constants';
import { Dropdown } from '@app/core/models/dropdown.model';
import { ReservationService } from '@core/services/reservation.service';
import { ModalService } from '@app/shared/services/modal';
import { TypeaheadService } from '@app/core/services/typeahead.service';
import { commonId } from 'app/core/constants/common-constants';

@Component({
    selector: 'dtr-hub-room-location-request',
    templateUrl: './room-location-request.component.html',
    styleUrls: ['./room-location-request.component.scss']
})
export class RoomLocationRequestComponent implements OnInit {
    @Input() campus;
    @Input() collapsed;
    @Input() dscbDetails;
    rlrDscrbData: any;
    edit = false;
    UI_CONSTANTS: any = {};
    @ViewChild('infoIcon') infoElRef: ElementRef;
    @ViewChild('infoModal') public infoModal: ElementRef;
    showModal = false;
    private _accommodation: Accommodation;
    requestedText = 'None Selected';
    arrivingEarly = false;
    wdprSelectRoomSvcReq0: ElementRef;
    wdprSelectRoomSvcReq1: ElementRef;
    roomSvcReqDropdown: Dropdown[] = new Array(2);
    arrivingEarlyCheckboxState = false;
    arrivingEarlyCode: any;
    commonData: any;

    constructor(
        private renderer: Renderer2,
        public modalService: ModalService,
        public reservationService: ReservationService,
        public typeaheadService: TypeaheadService) {
        this.initializeConstants();
    }

    private initializeConstants() {
        this.UI_CONSTANTS.rlrHeading = rlrConstants.rlrHeading;
        this.UI_CONSTANTS.rlrHeadingAlt = rlrConstants.rlrHeadingAlt;
        this.UI_CONSTANTS.rlrEdit = rlrConstants.rlrEdit;
        this.UI_CONSTANTS.rlrEditAlt = rlrConstants.rlrEditAlt;
        this.UI_CONSTANTS.rlrRequested = rlrConstants.rlrRequested;
        this.UI_CONSTANTS.rlrRoomSvcReqDropdownNames = rlrConstants.rlrRoomSvcReqDropdownNames;
        this.UI_CONSTANTS.rlrArivingEarly = rlrConstants.rlrArivingEarly;
        this.UI_CONSTANTS.rlrMoreInfoAlt = rlrConstants.rlrMoreInfoAlt;
        this.UI_CONSTANTS.rlrTextSelect = rlrConstants.rlrTextSelect;
        this.UI_CONSTANTS.rlrTextDropdown = rlrConstants.rlrTextDropdown;
        this.UI_CONSTANTS.rlrInfoHeading = rlrConstants.rlrInfoHeading;
        this.UI_CONSTANTS.rlrInformation = rlrConstants.rlrInformation;
    }

    @ViewChild('wdprSelectRoomSvcReq0') set content0(content0: ElementRef) {
        this.wdprSelectRoomSvcReq0 = content0;
        this.addListenerToDropdown0();
    }

    @ViewChild('wdprSelectRoomSvcReq1') set content1(content1: ElementRef) {
        this.wdprSelectRoomSvcReq1 = content1;
        this.addListenerToDropdown1();
    }

    @Input() set accommodation(accommodation: Accommodation) {
        this._accommodation = accommodation;
    }

    ngOnInit() {
        this.arrivingEarly = this.isArrivingEarly();
        this.arrivingEarlyCheckboxState = this.arrivingEarly;
        this.initializeDropdowns();
        this.rlrDscrbData = _.find(this.dscbDetails, function (module) {
            return module.id === rlrConstants.moduleId;
        });
        this.commonData = _.find(this.dscbDetails, function(module) {
            return module.id === commonId;
        });
    }

    private updateDropdown(sequence: number, preSelectionValue?: string,
        hideOptionValue?: string) {
        if (!this.roomSvcReqDropdown[sequence]) {
            this.roomSvcReqDropdown[sequence] = {};
        }
        const dropdown = this.roomSvcReqDropdown[sequence];

        // Include 'None' option in the dropdown options
        dropdown.options = ['None', ..._.reduce(this._accommodation.profileInfos, (profileInfoArr, profile) => {
            if (hideOptionValue !== profile.description) {
                profileInfoArr.push(profile.description);
            }
            return profileInfoArr;
        }, [])];
        if (_.isUndefined(preSelectionValue)) {
            preSelectionValue = dropdown.value;
        }
        dropdown.value = preSelectionValue;
        const index = _.indexOf(dropdown.options, preSelectionValue);
        dropdown.index = index === -1 ? undefined : index;
    }

    private initializeDropdowns() {
        const savedSelectionArr = this.getSavedDropdownSelections();
        // Check if both dropdowns have 'None' as an option
        const hasNoneOption = savedSelectionArr.includes('None') && savedSelectionArr.length === 2;
        // Update roomcheckinPayload based on saved selections
        this.updateRoomcheckinPayload(savedSelectionArr, hasNoneOption);
        // Update requestedText based on saved selections and 'None' option
        this.requestedText = hasNoneOption ? 'None Selected' : savedSelectionArr.length ? savedSelectionArr.join(', ') : 'None Selected';
        // Update dropdowns including/excluding 'None' if present in saved selections
        this.updateDropdown(0, hasNoneOption ? 'None' : savedSelectionArr[0], savedSelectionArr[1]);
        this.updateDropdown(1, hasNoneOption ? 'None' : savedSelectionArr[1], savedSelectionArr[0]);
    }

    private getSavedDropdownSelections() {
        return _.map(_.intersectionBy(this._accommodation.profiles,
            this._accommodation.profileInfos, 'description'),
            'description');
    }

    private isArrivingEarly() {
        const guestArrivingEarly = _.find(this._accommodation.profiles, ['code', 'TOPRR']);
        const payload = this.reservationService.roomcheckinPayload;

        const profileInfoObj = {
            'referenceValue': this._accommodation.accommodationId,
            'referenceKey': 'DREAMS_TC',
            'selectedList': [guestArrivingEarly]
        };
        payload.profileInfos.push(profileInfoObj);
        return guestArrivingEarly ? guestArrivingEarly.selected : false;
    }

    private addListenerToDropdown0() {
        if (_.isUndefined(this.wdprSelectRoomSvcReq0)) {
            this.addRemoveListener(this.roomSvcReqDropdown[0]);
            return;
        }
        this.roomSvcReqDropdown[0].listener =
            this.renderer.listen(this.wdprSelectRoomSvcReq0.nativeElement, 'item-selected',
                (evt) => {
                    const selectedValue = evt.detail.value;
                    this.updateRoomcheckinPayload([selectedValue, this.roomSvcReqDropdown[1].value], selectedValue === 'None');
                    this.updateDropdown(0, selectedValue, this.roomSvcReqDropdown[1].value);
                    this.updateDropdown(1, undefined, selectedValue);
                });

    }

    private addListenerToDropdown1() {
        if (_.isUndefined(this.wdprSelectRoomSvcReq1)) {
            this.addRemoveListener(this.roomSvcReqDropdown[1]);
            return;
        }
        this.roomSvcReqDropdown[1].listener =
            this.renderer.listen(this.wdprSelectRoomSvcReq1.nativeElement, 'item-selected',
                (evt) => {
                    const selectedValue = evt.detail.value;
                    this.updateRoomcheckinPayload([selectedValue, this.roomSvcReqDropdown[0].value], selectedValue === 'None');
                    this.updateDropdown(1, selectedValue, this.roomSvcReqDropdown[0].value);
                    this.updateDropdown(0, undefined, selectedValue);
                });
    }

    addRemoveListener(dropdown: Dropdown) {
        if (dropdown.listener) {
            dropdown.listener();
            dropdown.listener = undefined;
        }
    }

    get roomSvcReqDropdownAriaLabel0() {
        let dropdownAriaLabel =
            `${this.rlrDscrbData.descriptions[0].sections.roomRequest[0]},${this.rlrDscrbData.sections.dropdownText}`;
        if (!_.isUndefined(this.roomSvcReqDropdown[0].index)) {
            dropdownAriaLabel += `, ${this.roomSvcReqDropdown[0].value},`;
            dropdownAriaLabel += ` ${this.roomSvcReqDropdown[0].index}${this.rlrDscrbData.descriptions[0]
                .sections.bodyEdit}${this.roomSvcReqDropdown[0].options.length - 1}`;
        }
        return dropdownAriaLabel;
    }

    get roomSvcReqDropdownAriaLabel1() {
        let dropdownAriaLabel =
            `${this.rlrDscrbData.descriptions[0].sections.roomRequest[1]},${this.rlrDscrbData.sections.dropdownText}`;
        if (!_.isUndefined(this.roomSvcReqDropdown[1].index)) {
            dropdownAriaLabel += `, ${this.roomSvcReqDropdown[1].value},`;
            dropdownAriaLabel += ` ${this.roomSvcReqDropdown[1].index}${this.rlrDscrbData.descriptions[0]
                .sections.bodyEdit}${this.roomSvcReqDropdown[1].options.length - 1}`;
        }
        return dropdownAriaLabel;
    }

    getArrivingEarlyAriaLabel() {
        const checkboxState = this.arrivingEarlyCheckboxState ? this.rlrDscrbData.descriptions[0].sections.checkboxAccessibilityChecked
            : this.rlrDscrbData.descriptions[0].sections.checkboxAccessibilityUnChecked;
        return checkboxState + this.rlrDscrbData.descriptions[0].sections.preferRoomReady + this.rlrDscrbData.descriptions[0]
            .sections.footerAccesability;
    }

    onCheckBoxChanged(e) {
        this.arrivingEarlyCheckboxState = e.detail.value;
        const payload = this.reservationService.roomcheckinPayload;
        this.updateRoomcheckinPayload([this.roomSvcReqDropdown[0].value, this.roomSvcReqDropdown[1].value], false);
    }

    updateRoomcheckinPayload(selectedValues, hasNoneOption) {
        const payload = this.reservationService.roomcheckinPayload;
        const earlyArrivalObj = _.find(payload.profileInfos[0].selectedList, ['code', 'TOPRR']);
        // Remove 'None' option if present in selectedValues
        selectedValues = selectedValues.filter(value => value !== 'None');

        if (earlyArrivalObj) {
            earlyArrivalObj.selected = this.arrivingEarlyCheckboxState;
            this.arrivingEarlyCode = earlyArrivalObj;
        }

        let selectedProfileList = [];

        if (selectedValues.length > 0) {
            selectedProfileList = _.filter(this._accommodation.profileInfos, function (o) {
                return (_.indexOf(selectedValues, o.description) !== -1);
            });
        }
        // Add 'None' option if both dropdowns have 'None' selected
        if (hasNoneOption) {
            payload.profileInfos[0].selectedList = selectedProfileList.filter(item => item.description !== 'None');
        } else {
            payload.profileInfos[0].selectedList = selectedProfileList;
        }

        if (this.arrivingEarlyCheckboxState) {
            selectedProfileList.push(this.arrivingEarlyCode);
        }
    }
    openModal() {
        this.showModal = true;
        this.modalService.open('info-modal');
    }

    closeModal() {
        this.modalService.close('info-modal');
        this.showModal = false;
        this.infoElRef.nativeElement.focus();
    }

    onKeyup(e, list, dropdownValue) {
        if (e.target.className === 'room-select-1') {
            const newValue = this.typeaheadService.focusOnNextMatch(e, list, dropdownValue, this.wdprSelectRoomSvcReq0);
            if (newValue) {
                this.roomSvcReqDropdown[0].index = _.indexOf(list, newValue);
            }
        }
        if (e.target.className === 'room-select-2') {
            const newValue = this.typeaheadService.focusOnNextMatch(e, list, dropdownValue, this.wdprSelectRoomSvcReq1);
            if (newValue) {
                this.roomSvcReqDropdown[1].index = _.indexOf(list, newValue);
            }
        }
    }
}
