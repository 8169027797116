export const rlrHeading = 'Room Location Requests';
export const rlrHeadingAlt = 'Room Location Requests';
export const rlrEdit = 'Edit';
export const rlrEditAlt = 'Edit';
export const rlrRequested = 'Requested: ';
export const rlrRoomSvcReqDropdownNames = ['Room Request 1 (optional)', 'Room Request 2 (optional)'];
export const rlrArivingEarly = 'Arriving early? Check here if having your room ready for early arrival is a higher priority ' +
'than any of the requests you have selected';
export const rlrMoreInfoAlt = 'More Information, Button';
export const rlrTextSelect = 'Select';
export const rlrTextDropdown = 'dropdown';
export const rlrInfoHeading = 'Room Location Requests';
export const rlrInformation = 'We will make an attempt to acccommodate your request for a room ready prior to the Resort’s ' +
' standard check-in time. However, our ability to satisfy this request or any other requests you’ve made depends on factors ' +
' beyond our control, such as the previous evening’s occupancy.';
export const protocol = 'https://';
export const moduleId = 'roomRequests';




