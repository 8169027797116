import * as _ from 'lodash';

export class Address {

    public static fromJSON(data: any): Address {

        return new Address(
            data.addressLineOne,
            data.addressLineTwo,
            data.city,
            data.country,
            data.postalCode,
            data.state
        );
    }

    constructor(
        private _addressLineOne: string,
        private _addressLineTwo: string,
        private _city: string,
        private _country: string,
        private _postalCode: string,
        private _state: string
    ) {}

    public get addressLineOne(): string { return this._addressLineOne; }

    public set addressLineOne(addressLineOne: string) { this._addressLineOne = addressLineOne; }

    public get addressLineTwo(): string { return this._addressLineTwo; }

    public set addressLineTwo(addressLineTwo: string) { this._addressLineTwo = addressLineTwo; }

    public get city(): string { return this._city; }

    public set city(city: string) { this._city = city; }

    public get country(): string { return this._country; }

    public set country(country: string) { this._country = country; }

    public get postalCode(): string { return this._postalCode; }

    public set postalCode(postalCode) { this._postalCode = postalCode; }

    public get state(): string { return this._state; }

    public set state(state: string) { this._state = state; }
}
