export const orderStatus = {
    SHIP_TO_RESORT: 'SHIP_TO_RESORT',
    SHIP_TO_HOME: 'COMPLETED',
    NO_FULFILLMENT: 'NO_FULFILLMENT',
    PICKUP: 'FULFILL_ON_SITE'
};

export const holdFulfillReason = {
    SHIP_TO_RESORT: 'SHIP_TO_RESORT',
    GUEST_PROVIDED_RESORT_ADDRESS: 'GUEST_PROVIDED_RESORT_ADDRESS'
};
export const moduleId = 'magicBand';

