import * as _ from 'lodash';
import { OLCIEligibility } from './olci-eligibility.model';

export class ResortReservation {

    public static fromJSON(data: any): ResortReservation {
        return new ResortReservation(
            data.reservationNumber,
            data.travelPlanId,
            data.reservationStatus,
            data.arrivalDateTime,
            data.departureDateTime,
            data.facilityId,
            data.olciEligibility,
            data.facilityName
        );
    }

    constructor(
        private _reservationNumber: string,
        private _travelPlanId: string,
        private _reservationStatus: string,
        private _arrivalDateTime: string,
        private _departureDateTime: string,
        private _facilityId: number,
        private _olciEligibility: OLCIEligibility,
        private _facilityName: string
    ) {}

    public get reservationNumber(): string { return this._reservationNumber; }

    public get travelPlanId(): string { return this._travelPlanId; }

    public get reservationStatus(): string { return this._reservationStatus; }

    public get arrivalDateTime(): string { return this._arrivalDateTime; }

    public get departureDateTime(): string { return this._departureDateTime; }

    public get facilityId(): number { return this._facilityId; }

    public get olciEligibility(): OLCIEligibility { return this._olciEligibility; }

    public get facilityName(): string { return this._facilityName; }
}
