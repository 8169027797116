export const CONFIG_CONSTANTS = {
    siteIdKey: 'siteId',
    apiUrlKey: 'webApiUrl',
    envKey: 'environment',
    defaultVersionKey: 'webApiDefaultVersion',
    prodEnvValue: 'production',
    cdnListKey: 'cdnList',
    cdnPathKey: 'cdnPath',
};

const explorerServicePath = 'explorer-service/';

export const LOGIN_URL = '/login';

export const API_CONSTANTS = {
    authEndpoint: 'authz/public',
    authStatusEndpoint: 'authentication/status',
    createPersonalScheduleItemEndpoint: 'keyring-service/personal-schedule-item',
    currencyExchangeRateEndpoint: 'convert-currency/exchange-rate',
    detailsEndpoint: explorerServicePath + 'details-entity-simple',
    detailsScheduleEndpoint: explorerServicePath + 'details-entity-schedule',
    detailsMealPeriodScheduleEndpoint: explorerServicePath + 'meal-periods-schedule',
    diningAvailabilityEndpoint: explorerServicePath + 'dining-availability',
    friendsAndFamilyEndpoint: 'keyring-service/friends-and-family',
    listSvcEndpoint: 'list-service/details-slugs',
    reservationConflictingItemsEndpoint: 'reservation-service/conflicting-items',
    mediaServiceEndpoint: 'media-service/media-engine-bundles',
    webApiVersionEndpoint: 'version',
    resourceBundleEndpoint: 'language-bundles',
    jwtCookieName: 'pep_jwt_token',
    personalizedContentEndpoint: 'recommendation-service/personalized-content',
    listAncestorEntitiesEndpoint: explorerServicePath + 'list-ancestor-entities',
    mediaListEndpoint: 'media-list'
};

export const SCHEDULE_CONSTANTS = {
    fullDateFormat: 'dddd, MMMM D, YYYY',
    scheduleDateFormat: 'YYYY-MM-DD',
    operating: 'Operating',
    refurbishment: 'Refurbishment',
    extraMagicHours: 'Extra Magic Hours',
    extraMagicHourMagicMorning: 'Extra Magic Hour and Magic Morning',
    specialTicketedEvent: 'Special Ticketed Event',
    closed: 'Closed',
    performanceTime: 'Performance Time',
    noPerformance: 'No Performance',
    timeOutputFormat: 'h:mm A',
    timeParseFormat: 'HH:mm:ss',
    scheduleTimesThreshold: 3
};

export const MEDIA_ENGINE_CONSTANTS = {
    selectors: {
        titleBarClass: 'me-title-bar',
        titleClass: 'me-title',
        ctaBtnClass: 'me-cta-btn',
        fsBtnClass: 'me-fs-btn',
        headerMeFsClass: 'me-fullscreen',
        headerClass: 'finder-syndicated-header-wrapper'
    }
};

export const DESTINATION_ID_CONSTANTS = {
    wdw: '80007798;entityType=destination',
    dlr: '80008297;entityType=destination'
};

export const LIST_RESOLVER_FILTER_MAP = {
    attractions: ['Attraction', 'Entertainment'],
    entertainment: ['Attraction', 'Entertainment'],
};

export const LIST_TITLE_MAP = {
    attractions: 'Attractions and Entertainment',
    entertainment: 'Attractions and Entertainment',
};

export const DESTINATION_NAME_CONSTANTS = {
    wdw: 'Walt Disney World',
    dlr: 'Disneyland Resort'
};

export const MEAL_PERIOD_CONSTANTS = {
    mealPeriodsOrder: [
        'breakfast',
        'brunch',
        'lunch',
        'dinner',
        'lounge',
        'late-night'
    ]
};

export const LOCALES = {
    en_US: 'en_US'
};

export const ANALYTICS = {
    trackLink: 'analyticsLinkId'
};

// This Config is used for adding analyticsTrackLink properties to html retrieved from dscribe
export const ANALYTICS_TRACK_LINKS = [
    {
        element: 'rrnTermsConditions',
        id: `WDW_OLCI_ViewPrivacyPolicy`
    },
    {
        element: 'mdxApp',
        id: 'WDW_OLCI_MDXApp'
    }
];
