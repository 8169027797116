/**
 * This class aims to decorate the current JS native brige for DTR module
 *
 * see https://github.disney.com/WDPR-Release/wdpr-ra-javascript-native-bridge
 *
 */
import { Injectable } from '@angular/core';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { NATIVE_BRIDGE } from './native-brige.constants';
import { Subject } from 'rxjs';


@Injectable()
export class NativeBridgeService {

    private windowRef: any = {};
    private nativeBridge: any = {};
    private device: any = {};

    private subject = new Subject<any>();

    constructor(private logger: LoggerService) {

        // global window native browser
        this.windowRef = window;

        if (this.windowRef.hasOwnProperty(NATIVE_BRIDGE.windowNS)) {

            // create an instance of RA plugin to send message
            this.nativeBridge = this.windowRef.disney.nativeBridgeFactory.createNativeBridge();

            // expose the device info
            this.device = this.windowRef.disney.device;

        } else {
            this.logger.warn('wdpr-ra-javascript-native-bridge is not loaded!');
        }
    }

    /**
     * @name isEmbedded
     * @description Determine if the current view is gets loaded in a WebView
     *              verifying if userAgent contains one of this two options:
     *              - DisneyRAWebView/iOS
     *              - DisneyRAWebView//Android
     *
     * @returns bool    true on success otherwise false
     */
    public isEmbedded(): boolean {
        return  this.device.embedded;
    }

    /**
     * @name getDevice
     * @description Get the information of the current device
     *
     * @example
     *
     * @returns Object
     */
    public getDevice(): object {
        return this.device;
    }

    /**
     * @name sendMessage
     * @description Send a message to a Native App via RA JS Native bridge
     *
     * @param type                  The type of the message that you want to sent
     * @param [params]              A JSON object of keys and values
     * @param [sendFailedCallback]  A closure that will be invoked if he message fails to send.
                                    It will have 3 parameters: commandName, params, error
     * @param [commandName]         The identifier for the message (in custom message only)
     *
     * @returns void
     */
    public sendMessage(type: string, params?, sendFailedCallback?, commandName?: string) {

        switch (type) {
            case NATIVE_BRIDGE.messagesTypes.custom:
                // prefix all request messages commands
                // needed for Native App implementation
                commandName = [
                    NATIVE_BRIDGE.windowNS,
                    NATIVE_BRIDGE.dtrBridgePlugin,
                    commandName
                ].join('.');
                this.nativeBridge.sendCustomMessage(commandName, params, sendFailedCallback);
                break;

            case NATIVE_BRIDGE.messagesTypes.ready:
                this.nativeBridge.sendReadyMessage(params, sendFailedCallback);
                break;

            case NATIVE_BRIDGE.messagesTypes.error:
                this.nativeBridge.sendErrorMessage(params, sendFailedCallback);
                break;

            case NATIVE_BRIDGE.messagesTypes.close:
                this.nativeBridge.sendCloseMessage(params, sendFailedCallback);
                break;
        }
    }
}
