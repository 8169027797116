export const maskConfig = {
    'defaultMaskCharacter' : '#',
    'defaultUnmaskedLastDigitsLength' : 4,
    'Get Room checkin details' : {
        'maskAttributes' : [
            {'path' : 'chargeAccountDetails.loggedInGuest.card.nameType'},
            {'path' : 'chargeAccountDetails.loggedInGuest.card.name'},
            {'path' : 'chargeAccountDetails.loggedInGuest.card.number'},
            {'path' : 'chargeAccountDetails.loggedInGuest.card.expiration.month'},
            {'path' : 'chargeAccountDetails.loggedInGuest.card.expiration.year'},
            {'path' : 'chargeAccountDetails.loggedInGuest.card.address.addressLineOne'},
            {'path' : 'chargeAccountDetails.loggedInGuest.card.address.addressLineTwo'},
            {'path' : 'chargeAccountDetails.loggedInGuest.card.address.city'},
            {'path' : 'chargeAccountDetails.loggedInGuest.card.address.country'},
            {'path' : 'chargeAccountDetails.loggedInGuest.card.address.postalCode'},
            {'path' : 'chargeAccountDetails.loggedInGuest.card.address.state'},
            {'path' : 'chargeAccountDetails.guests.firstName'},
            {'path' : 'chargeAccountDetails.guests.lastName'},
            {'path' : 'mdxAccountInfo.email'},
            {'path' : 'mdxAccountInfo.address.addressLineOne'},
            {'path' : 'mdxAccountInfo.address.addressLineTwo'},
            {'path' : 'mdxAccountInfo.address.city'},
            {'path' : 'mdxAccountInfo.address.country'},
            {'path' : 'mdxAccountInfo.address.postalCode'},
            {'path' : 'mdxAccountInfo.address.state'},
            {'path' : 'prearrivalInfo.emailAddress'},
            {'path' : 'prearrivalInfo.mobilePhoneNumber'},
            {'path' : 'settlementMethods.card.expiration.month'},
            {'path' : 'settlementMethods.card.expiration.year'},
            {'path' : 'settlementMethods.card.name'},
            {'path' : 'settlementMethods.card.nameType'},
            {'path' : 'settlementMethods.card.number', 'ccNumberField': true},
            {'path' : 'settlementMethods.card.address.addressLineOne'},
            {'path' : 'settlementMethods.card.address.addressLineTwo'},
            {'path' : 'settlementMethods.card.address.city'},
            {'path' : 'settlementMethods.card.address.country'},
            {'path' : 'settlementMethods.card.address.postalCode'},
            {'path' : 'settlementMethods.card.address.state'},
            {'path' : 'travelPlanSegmentDetails.accommodation.guestReferences.age'},
            {'path' : 'travelPlanSegmentDetails.accommodation.guestReferences.ageType'},
            {'path' : 'travelPlanSegmentDetails.accommodation.guestReferences.guest.firstName'},
            {'path' : 'travelPlanSegmentDetails.accommodation.guestReferences.guest.lastName'}
           ]
    },
    'Commit Room checkin details' : {
        'maskAttributes' : [
            {'path' : 'address.addressLineOne'},
            {'path' : 'address.city'},
            {'path' : 'address.country'},
            {'path' : 'address.postalCode'},
            {'path' : 'address.addressLineOne'},
            {'path' : 'prearrivalInfo.emailAddress'},
            {'path' : 'prearrivalInfo.mobilePhoneNumber'},
            {'path' : 'settlementMethods.card.address.addressLineOne'},
            {'path' : 'settlementMethods.card.address.addressLineTwo'},
            {'path' : 'settlementMethods.card.address.city'},
            {'path' : 'settlementMethods.card.address.country'},
            {'path' : 'settlementMethods.card.address.postalCode'},
            {'path' : 'settlementMethods.card.address.state'},
            {'path' : 'settlementMethods.card.expiration.month'},
            {'path' : 'settlementMethods.card.expiration.year'},
            {'path' : 'settlementMethods.card.name'},
            {'path' : 'settlementMethods.card.nameType'},
            {'path' : 'settlementMethods.card.number', 'ccNumberField': true},
            {'path' : 'chargeAccountDetails.guests.firstName'},
            {'path' : 'chargeAccountDetails.guests.lastName'},
            {'path' : 'chargeAccountDetails.guests.pinNumber'}

        ]
    },
    'Get Reservation Summary details' : {
        'maskAttributes' : [
            {'path' : 'resortReservations.contactName'},
            {'path' : 'resortReservations.accommodations.contactName'},
            {'path' : 'resortReservations.accommodations.resort.phoneNumber'},
            {'path' : 'resortReservations.accommodations.resort.address.addressLine1'},
            {'path' : 'resortReservations.accommodations.resort.address.country'},
            {'path' : 'resortReservations.accommodations.resort.address.postalCode'},
            {'path' : 'resortReservations.accommodations.resort.links.phoneNumber'},
            {'path' : 'resortReservations.accommodations.transactionalGuestList.addressList.addressLine1'},
            {'path' : 'resortReservations.accommodations.transactionalGuestList.addressList.city'},
            {'path' : 'resortReservations.accommodations.transactionalGuestList.addressList.country'},
            {'path' : 'resortReservations.accommodations.transactionalGuestList.addressList.postalCode'},
            {'path' : 'resortReservations.accommodations.transactionalGuestList.emailList.email'},
            {'path' : 'resortReservations.accommodations.transactionalGuestList.name.firstName'},
            {'path' : 'resortReservations.accommodations.transactionalGuestList.name.lastName'},
            {'path' : 'resortReservations.accommodations.transactionalGuestList.phoneList.number'},
            {'path' : 'resortReservations.primaryGuest.addressList.addressLine1'},
            {'path' : 'resortReservations.primaryGuest.addressList.city'},
            {'path' : 'resortReservations.primaryGuest.addressList.country'},
            {'path' : 'resortReservations.primaryGuest.addressList.postalCode'},
            {'path' : 'resortReservations.primaryGuest.addressList.addressLine1'},
            {'path' : 'resortReservations.primaryGuest.emailList.email'},
            {'path' : 'resortReservations.primaryGuest.name.firstName'},
            {'path' : 'resortReservations.primaryGuest.name.lastName'}
        ]
    },
    'Establish APP session and get session details' : {
        'maskAttributes' : [
            {'path' : 'userProfile.name.firstName'},
            {'path' : 'userProfile.name.lastName'},
            {'path' : 'userProfile.address.addressLineOne'},
            {'path' : 'userProfile.address.addressLineTwo'},
            {'path' : 'userProfile.address.city'},
            {'path' : 'userProfile.address.state'},
            {'path' : 'userProfile.address.country'},
            {'path' : 'userProfile.address.zip'},
            {'path' : 'userProfile.address.province'},
            {'path' : 'userProfile.emailAddress'},
            {'path' : 'guestDetails.firstName'},
            {'path' : 'guestDetails.lastName'},
            {'path' : 'guestDetails.age'}
        ]
    },
    'Get APP Session Details' : {
        'maskAttributes' : [
            {'path' : 'paymentConfirmations.processorConfirmation.processorParameters.value'},
            {'path' : 'paymentConfirmations.paymentMethodDisplay.cardDisplay.cardToken.tokenVaultCode'},
            {'path' : 'paymentConfirmations.paymentMethodDisplay.cardDisplay.cardToken.token'},
            {'path' : 'paymentConfirmations.paymentMethodDisplay.cardDisplay.displayNumber.displayNumber', 'ccNumberField': true},
            {'path' : 'paymentConfirmations.paymentMethodDisplay.cardDisplay.expirationDate'},
            {'path' : 'paymentConfirmations.paymentMethodDisplay.cardDisplay.billingAddress.city'},
            {'path' : 'paymentConfirmations.paymentMethodDisplay.cardDisplay.billingAddress.country'},
            {'path' : 'paymentConfirmations.paymentMethodDisplay.cardDisplay.billingAddress.postalCode'},
            {'path' : 'paymentConfirmations.paymentMethodDisplay.cardDisplay.billingAddress.phoneNumber'},
            {'path' : 'paymentConfirmations.paymentMethodDisplay.cardDisplay.billingAddress.phoneCountryCode'},
            {'path' : 'paymentConfirmations.paymentMethodDisplay.cardDisplay.billingAddress.addressLine'},
            {'path' : 'paymentConfirmations.paymentMethodDisplay.cardDisplay.emailAddress'},
            {'path' : 'paymentConfirmations.paymentMethodDisplay.cardDisplay.embossedName'},
            {'path' : 'paymentConfirmations.paymentMethodDisplay.cardDisplay.firstName'},
            {'path' : 'paymentConfirmations.paymentMethodDisplay.cardDisplay.lastName'}

        ]
    }
};
