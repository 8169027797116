import * as _ from 'lodash';

export class Country {

    public static fromJSON(data: any): Country {
        return new Country(
            data.isoCountryCode2,
            data.isoCountryCode3,
            data.name,
            data.restricted,
            data.zipOptional
        );
    }

    constructor(
        private _isoCountryCode2: string,
        private _isoCountryCode3: string,
        private _name: string,
        private _restricted: boolean,
        private _zipOptional: boolean
    ) {}

    public get isoCountryCode2(): string { return this._isoCountryCode2; }

    public get isoCountryCode3(): string { return this._isoCountryCode3; }

    public get name(): string { return this._name; }

    public get restricted(): boolean { return this._restricted; }

    public get zipOptional(): boolean { return this._zipOptional; }
}
