import { Component, OnInit, Input, Inject } from '@angular/core';
import * as UI_CONSTANTS from '@app/hotel-account-charges/hotel-account-charges.constants';
import moment from 'moment';
import * as _ from 'lodash';
import { DataService } from '../core/services/data/data.service';
import { Subscription } from 'rxjs';
import { SessionDetails } from '@app/core/models/session-details.model';
import { RoutingService } from '../core/services/routing.service';
import * as commonConstants from '@app/core/constants/common-constants';
import { ActivatedRoute } from '@angular/router';
import { ReservationService } from '../core/services/reservation.service';
import { ConfigService } from '@app/core/config.service';
import { GlobalThemeService } from '@app/services/global-theme-service/global.theme.service';
import { CookieServices } from '@app/core/services';
import { WindowRef } from './window-ref';
import { cacheSessionIdKey, commonId } from 'app/core/constants/common-constants';
import { CacheDetails } from '@app/core/models/cache-details.model';
import {AuthService} from "@core/services/auth/auth.service";

@Component({
    selector: 'dtr-hub-hotel-account-charges',
    templateUrl: './hotel-account-charges.component.html',
    styleUrls: ['./hotel-account-charges.component.scss']
})
export class HotelAccountChargesComponent implements OnInit {
    @Input() isHACExpanded;
    @Input() collapsed;
    @Input() isAddFlow;
    @Input() campus;
    @Input() settlementMethods;
    @Input() mdxAccountInfo;
    @Input() accommodation;
    @Input() travelPlanSegmentId;
    @Input() dscbDetails;
    HotelAccChargData: any;
    sessionDetails: SessionDetails;
    appSessionDetailsSub: Subscription;
    errorResponse: any;
    @Input() saveInProgress;
    saving = false;
    formatedCardName: string;
    tabIndex = -1;
    commonData: any;

    edit = false;
    private UI_CONSTANTS;
    cardClass;
    config: any;
    chargeErrorMsg: string[];
    isIframeLoaded = false;
    isCreditCardUpdateBtnDisabled = false;
    sessionID: string;
    authSvc: AuthService;

    constructor(
        public route: ActivatedRoute,
        public dataService: DataService,
        public globalThemeService: GlobalThemeService,
        public reservationService: ReservationService,
        public routingService: RoutingService,
        private configService: ConfigService,
        private cookieService: CookieServices,
        private authService:AuthService,
        public windowRef: WindowRef
    ) {
        this.config = this.configService.config;
        this.authSvc = this.authService;
    }

    ngOnInit() {
        if (this.isHACExpanded) {
            this.edit = !this.edit;
            this.isCreditCardUpdateBtnDisabled = true;
            this.loadPaymentSheet();
        }

        this.UI_CONSTANTS = UI_CONSTANTS;
        this.getDsbData(this.dscbDetails);
        this.cardClass = this.getCardClass();
        this.loadSession();

        this.chargeErrorMsg = [];
        this.chargeErrorMsg.push(this.HotelAccChargData.descriptions[0].sections.ccRequired);
    }

    getDsbData(dsbData) {
        this.HotelAccChargData = _.find(dsbData, function(module) {
            return module.id === UI_CONSTANTS.moduleId;
        });
        this.commonData = _.find(this.dscbDetails, function(module) {
            return module.id === commonId;
        });
    }

    get yearPrefix() {
        const fullYear = moment().year();
        const century = fullYear.toString().substring(0, 2);
        return century;
    }

    getCardClass() {
        let cardName = '';
        if (this.settlementMethods && this.settlementMethods.card) {
            this.reservationService.setHACSaveSet(true);
            this.settlementMethods.card.nameType = this.UI_CONSTANTS.cardNameTypes[this.settlementMethods.card.nameType];
            cardName = this.settlementMethods.card.nameType;
            cardName = _.lowerCase(cardName);
            cardName = cardName.split(' ').join('-');
            return cardName;
        } else {
            this.reservationService.setHACSaveSet(false);
        }
    }

    loadPaymentSheet() {
        this.saving = true;
        this.tabIndex = 0;
        const establishSessionRequest = this.reservationService
            .getAPPEstablishSessionRequest(this.mdxAccountInfo, this.accommodation, this.travelPlanSegmentId);
        const isBeach = this.reservationService.isBeachResortReservation(this.accommodation);
        const appClientHeader = this.globalThemeService.getAPPClientHeader(isBeach);
        this.appSessionDetailsSub = this.dataService.establishSession(establishSessionRequest, appClientHeader)
            .subscribe({
              next: (res) => {
                this.sessionDetails = res;
                this.reservationService.setAPPSessionToken(this.sessionDetails.sessionToken);
                this.loadScript();
                setTimeout(() => {
                  this.isIframeLoaded = true;
                  this.reservationService.setHACSaveSet(false);
                  this.reservationService.setPaymentSheetLoaded(true);
                  this.saving = false;
                }, 5000);
              },
              error: (error) => {
                this.reservationService.setPaymentSheetLoaded(true);
                this.saving = false;
                this.routeToError(error.error);
              }
            });
    }

    loadScript(): void {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'payment-sheet-lib';
        script.async = true;
        script.src = this.config.paymentSheetJsURL;
        script.onerror = (error: any) => {
            this.routeToError(error);
        };
        document.getElementsByTagName('head')[0].appendChild(script);
    }

    getClientId() {
        const clientIds = this.config.clientIds;
        const isBeach = this.reservationService.isBeachResortReservation(this.accommodation);
        const campus = this.globalThemeService.getCampusClient(isBeach);
        return clientIds[campus];
    }

    loadSession() {
        let domain = this.globalThemeService.getHostName();
        if (this.globalThemeService.getHostPort()) {
            domain = domain + ':' + this.globalThemeService.getHostPort();
        }
        const styleURL = 'https://' + domain + this.config.baseURL +
            'assets/style/app/' + this.globalThemeService.getCampus() + '.css';
        this.windowRef.nativeWindow['onPaymentSheetReady'] = () => {
            this.windowRef.nativeWindow
                .paymentSheet
                .configure(this.getIframeConfiguration())
                .theme(
                    {},
                    styleURL
                )
                .loadSession()
                .then(() => {
                    this.isIframeLoaded = true;
                    this.reservationService.setHACSaveSet(false);
                    this.reservationService.setPaymentSheetLoaded(true);
                    this.saving = false;
                    this.sessionID = sessionStorage.getItem(cacheSessionIdKey);
                    if (!this.sessionID) {
                        this.sessionID = this.reservationService.generateRandomString(12);
                        sessionStorage.setItem(cacheSessionIdKey, this.sessionID);
                    }
                })
                .catch((e) => {
                    this.reservationService.setPaymentSheetLoaded(true);
                    this.saving = false;
                    this.routeToError(e);
                });
        };

        this.windowRef.nativeWindow.onPaymentSheetStateChange =
            this.onPaymentSheetStateChange;

        this.windowRef.nativeWindow.onPaymentSheetFormSubmit =
            this.onPaymentSheetFormSubmit;

        this.windowRef.nativeWindow.onPaymentSheetDvicBannerClick =
            this.onDVICBannerClick(this.routingService);
    }

    getIframeConfiguration() {
        let domain = this.globalThemeService.getHostName();
        if (this.globalThemeService.getHostPort()) {
            domain = domain + ':' + this.globalThemeService.getHostPort();
        }
        return {
            client: this.getClientId(),
            domain: 'https://' + domain,
            lang: this.globalThemeService.getRequestedLanguage().toLowerCase(),
            oauth: this.authSvc.accessToken,
            session: this.sessionDetails.sessionToken,
            conversationId: this.cookieService.getConverationId(),
            hmac: this.sessionDetails.hmac,
            manualReflow: false,
            showErrorIcons: false,
            showRequiredAsterisks: true,
            a11yLoadingCopy: 'Loading...',
            a11yLoadedCopy: 'Loading...',//,
            //TODO add back in if/when DVIC will be enabled
            dvicSettings: {
                pageId: 'olci',
                orderType: 'STCOLCI'
            }
            //END TODO
        };
    }

    onPaymentSheetStateChange() {
        console.log('In onPaymentSheetStateChange');
    }

    onPaymentSheetFormSubmit() {
        console.log('In onPaymentSheetFormSubmit');
    }

    onDVICBannerClick(routingSvc: RoutingService) {
        return (details) => {
            const cacheData: CacheDetails = this.reservationService.formatCacheData(this.reservationService.roomcheckinPayload);
            this.dataService.postCacheDetails(cacheData, this.sessionID, this.travelPlanSegmentId).subscribe({ next: cacheResponse => {
                routingSvc.routeToExternalURL(details.link);
            }, error: (error) => {
                this.saving = false;
                routingSvc.routeToExternalURL(details.link);
            }});
        };
    }

    routeToError(error: any) {
        const reservationNo = this.route.snapshot.paramMap.get('reservationNo');
        this.errorResponse = error;
        const routeURL = commonConstants.routeURLs.SLASH +
            commonConstants.routeURLs.BASE_URL +
            commonConstants.routeURLs.ERROR +
            commonConstants.routeURLs.SLASH + reservationNo;
        this.routingService.routeToRouteURL(routeURL);
    }

    showChargesErrorMessage() {
        return !this.reservationService.isHACSaveSet()
            && this.saveInProgress;
    }
}
