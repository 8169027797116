import { Component, OnInit, Inject } from '@angular/core';
import { GlobalThemeService } from './services/global-theme-service/global.theme.service';
import { ConfigService } from './core/config.service';
import { OlciAnalyticsService } from './shared/services/analytics/analytics.service';

@Component({
    selector: 'dtr-hub-root',
    templateUrl: './app.component.html',
    styleUrls: ['app.component.scss']
})

export class AppComponent implements OnInit {
    config: any;

    constructor(
        public globalThemeService: GlobalThemeService,
        private configService: ConfigService,
        private olciAnalyticsService: OlciAnalyticsService
    ) {
        this.config = this.configService.config;
        this.olciAnalyticsService.initAnalytics();
    }

    ngOnInit() {
        const campus = this.globalThemeService.getCampus();
        const link = document.createElement('link');
        link.rel = 'icon';
        link.type = 'image/x-icon';
        link.href = this.config.iconURL[campus];
        document.getElementsByTagName('head')[0].appendChild(link);
    }
}
