/*! Copyright Disney. All rights reserved. */

/**
 * Miscellaneous utility functions
 */
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { maskConfig } from './mask-config';
import {maskConfigConstants} from './maskconfig-constants';

@Injectable()
export class MaskUtil {

    constructor() {
    }
    /*
        Returns a copy of the value passed masked with the maskedCharacter with first 'length' characters masked
        If length is not passed, it masks the entire string
        If maskCharacter is not passed , it uses the 'defaultMaskCharacter' defined in 'mask-config.json'
    */
    maskValue(value, length, maskCharacter) {
        let maskedValue = '';
        if (value && value.length > 0) {
            let character;
            let loopsize;
            let remainingDigits = '';

            if (!_.isNull(length) && !_.isUndefined(length)) {
                remainingDigits = value.substring(length);
                loopsize = value.length - remainingDigits.length;
            } else {
                loopsize = value.length;
            }

            if (!_.isNull(maskCharacter) && !_.isEmpty(maskCharacter) && !_.isUndefined(maskCharacter)) {
                character = maskCharacter;
            } else {
                character = maskConfig.defaultMaskCharacter;
            }

            maskedValue = _.repeat(character, loopsize);
            maskedValue += remainingDigits;
            return maskedValue;
        } else {
            return value;
        }

    }
    /*
         Retrieves a copy of the 'sourceObject' masked
         'maskConfigKey' is a key in the mask-config.json with which we retrieve the value which provides the
         specific attributes to mask in the sourceObject
     */
    retrieveMaskedObject(maskConfigKey, sourceObject) {
        const maskConfigDetail = _.get(maskConfig, maskConfigKey);
        return this.maskFieldsInObject(sourceObject, maskConfigDetail.maskAttributes);
    }

    // Returns a clone of the 'unmaskedObj' passed with the fields specified in 'maskAttributes' array masked
    maskFieldsInObject(unmaskedObj, maskAttributes) {
        const that = this;
        const objectToMask = _.clone(unmaskedObj, true);
        if (!_.isNull(maskAttributes) && !_.isEmpty(maskAttributes)) {
            _.each(maskAttributes, maskAttributesInObject);
        }

        return objectToMask;

        /*
              Mask the field in the cloned sourceObject, which is specified by the 'path' property in maskAttr
             'path' specifies the entire hierarchy from top to bottom of the field required to be masked

             e.g  maskAttr : [{"path" : "settlementMethods.card.number" , "length" :12 , "maskCharacter" : "x"}]

             If length is not specified, it masks the entire string
             If maskCharacter is not specified , it uses the 'defaultMaskCharacter' defined in 'mask-config.json'

             sourceObject
                   {
                           settlementMethods : [{
                                          card : {
                                                      number : 4444333322221111
                                          }
                                      ]
                  }

             output :
                      {
                          settlementMethods : [{
                                          card : {
                                                      number : xxxxxxxxxxxx1111
                                          }
                                      ]
                      }
        */
        function maskAttributesInObject(maskAttr) {
            const maskFieldKey = _.get(maskAttr, maskConfigConstants.pathKey);
            if (_.isString(maskFieldKey) && maskFieldKey.length > 0) {
                const maskFieldObjArr = maskFieldKey.split('.');
                maskObj(objectToMask, maskFieldObjArr, 0);
            }

            /*
               If the 'objToMask' is an array, it recursively calls itself for each element
               Else it masks the path specified in the 'path' field of maskAttr
            */
            function maskObj(objToMask, maskFieldObjArr, index) {
                 if (_.isArray(objToMask)) {
                    for (let i = 0; i < objToMask.length; i++) {
                         maskObj(objToMask[i], maskFieldObjArr, index);
                    }
                } else if (_.isObject(objToMask)) {
                     maskIndividualObj(objToMask);
                }

                /*
                    Fetches the field specified by maskFieldObjArr[index] in the 'individualElement' object as childObj
                    If the value is a string/number/boolean , it will mask that field in the 'individualElement'
                    Else it recursively calls the 'maskObj' function passing the 'childObj' as the first parameter
                    and index value incremented by 1, to continue the process recursively for next level down the hierarchy
                */
                function maskIndividualObj(individualElement) {
                    const childObj = _.get(individualElement, maskFieldObjArr[index]);
                     if (_.isString(childObj) || _.isNumber(childObj) || _.isBoolean(childObj)) {
                        maskField(individualElement, maskFieldObjArr[index]);
                    } else {
                         maskObj(childObj, maskFieldObjArr, index + 1);
                    }
                }

                function maskField(parentObj, childObj) {
                     let valueToMask = _.get(parentObj, childObj);
                        valueToMask = valueToMask.toString();
                        _.set(parentObj, childObj, that.maskValue(valueToMask, getLengthToMask(maskAttr, valueToMask),
                            _.get(maskAttr, maskConfigConstants.maskCharacterKey)));
                }
            }
        }

        /*
            Gets the length of the field to mask
            For fields marked with 'ccNumberField' as true in config file, it calculates the length based on the
            value of 'unmaskedLastDigitsLength' configured for that field
        */
        function getLengthToMask(maskAttr, valueToMask) {
            let length;
             const ccNumberField = _.get(maskAttr, maskConfigConstants.ccNumberFieldKey);
             if (!_.isUndefined(ccNumberField) && ccNumberField === true) {
                let unmaskedLength = _.get(maskAttr, maskConfigConstants.unmaskedLastDigitsLengthKey);
                if (!unmaskedLength) {
                    unmaskedLength = maskConfig.defaultUnmaskedLastDigitsLength;
                }
                length = valueToMask.length > unmaskedLength ? valueToMask.length - unmaskedLength : 0;
            } else {
                length = _.get(maskAttr, maskConfigConstants.lengthKey);
            }
            return length;
        }
    }
}
