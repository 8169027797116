import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class CookieServices {
    envOverrideCookieName = 'appEnvOverride';
    conversationIdCookieName = 'Conversation_UUID';
    localeCookieName = 'languageSelection_jar_aka';
    localecookieName = 'languageSelection_jar_aka';
    SWIDName = 'SWID';
    swidName = 'swid';
    envOverride: string;
    conversationId: string;
    localeCookie: string;
    swidCookie: string;

    constructor(private cookieService: CookieService) { }

    getConverationId(): string {
        try {
            this.conversationId = this.cookieService.get(
                this.conversationIdCookieName
            );
        } catch (e) {
            console.log('Error parsing conversationId cookie', e);
        }

        return this.conversationId;
    }

    getEnvOverride(): string {
        try {
            this.envOverride = this.cookieService.get(
                this.envOverrideCookieName
            );
        } catch (e) {
            console.log('Error parsing Env Override cookie', e);
        }

        return this.envOverride;
    }

    getLocale(): string {
        try {
            this.localeCookie = this.cookieService.get(
                this.localeCookieName
            );
        } catch (e) {
            try {
                this.localeCookie = this.cookieService.get(
                    this.localecookieName
                );
            } catch (e) {
                console.log('Error parsing localeCookie', e);
            }
        }

        return this.localeCookie;
    }

    getSwidCookie(): string {
        if (this.swidCookie) {
            return this.swidCookie;
        }
        try {
            this.swidCookie = this.cookieService.get(
                this.SWIDName
            );
        } catch (e) {
            try {
                this.swidCookie = this.cookieService.get(
                    this.swidName
                );
            } catch (e) {
                console.log('Error parsing swidCookie', e);
            }
        }
        return this.swidCookie;
    }
}
