import { InjectionToken, FactoryProvider } from '@angular/core';

export const DOCUMENT = new InjectionToken<Document>('document');

const documentProvider: FactoryProvider = {
    provide: DOCUMENT,
    useFactory: () => document
};

export const DOCUMENT_PROVIDERS = [
    documentProvider
];
