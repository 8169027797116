import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ReservationDetailsComponent } from './reservation-details/reservation-details.component';
import { TncPageComponent } from './tnc-page/tnc-page.component';
import { ErrorBannerComponent } from './error-banner/error-banner.component';

const routes: Routes = [
    {
        path: 'resort-reservation/:reservationNo',
        component: HomeComponent,
    },
    {
        path: ':locale/resort-reservation/:reservationNo',
        component: HomeComponent,
    },
    {
        path: ':locale/trip/online-check-in/resort-reservation/:reservationNo',
        component: HomeComponent,
    },
    {
        path: 'resort-reservation-id/:reservationNo',
        component: HomeComponent,
    },
    {
        path: ':locale/resort-reservation-id/:reservationNo',
        component: HomeComponent,
    },
    {
        path: ':locale/trip/online-check-in/resort-reservation-id/:reservationNo',
        component: HomeComponent,
    },
    {
        path: ':locale/room-ready-notifications-terms-and-conditions',
        component: TncPageComponent
    },
    {
        path: 'room-ready-notifications-terms-and-conditions',
        component: TncPageComponent
    },
    {
        path: ':locale/trip/online-check-in/room-ready-notifications-terms-and-conditions',
        component: TncPageComponent
    },
    {
        path: ':locale/pet-registration-terms-and-conditions',
        component: TncPageComponent
    },
    {
        path: 'pet-registration-terms-and-conditions',
        component: TncPageComponent
    },
    {
        path: ':locale/trip/online-check-in/pet-registration-terms-and-conditions',
        component: TncPageComponent
    },
    {
        path: 'error/:reservationNo',
        component: ErrorBannerComponent
    },
    {
        path: ':locale/error/:reservationNo',
        component: ErrorBannerComponent
    },
    {
        path: ':locale/trip/online-check-in/error/:reservationNo',
        component: ErrorBannerComponent
    },
    {
        path: 'error',
        component: ErrorBannerComponent
    },
    {
        path: ':locale/error',
        component: ErrorBannerComponent
    },
    {
        path: ':locale/trip/online-check-in/error',
        component: ErrorBannerComponent
    },
    {
        path: '',
        component: ReservationDetailsComponent,
    },
    {
        path: ':locale/trip/online-check-in',
        component: ReservationDetailsComponent,
    },
    {
        path: ':locale',
        component: ReservationDetailsComponent,
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
