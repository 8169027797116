import * as _ from 'lodash';
import { Country } from './country.model';
import { States } from './states.model';

export class CountryAndStateDetails {

    public static fromJSON(data: any): CountryAndStateDetails {
        const countries = Array.isArray(data.countries) ?
            data.countries.map(entry => Country.fromJSON(entry)) : [];

        return new CountryAndStateDetails(
            countries,
            data.states
        );
    }

    constructor(
        private _countries: Country[],
        private _states: States
    ) {}

    public get countries(): Country[] { return this._countries; }

    public get states(): States { return this._states; }
}
