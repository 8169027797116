import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigService } from './config.service';
import { LoggerModule } from '@wdpr/ra-angular-logger';
import { ErrorHandlerModule } from '@wdpr/ra-angular-error-handler';
import { DataService, CookieServices } from '@app/core/services';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor } from './interceptors';
import { ReservationService } from './services/reservation.service';
import { RoutingService } from './services/routing.service';
import { DScribeService } from './services/dscribe.service';
import { WindowRef } from '@app/hotel-account-charges/window-ref';
import { TypeaheadService } from './services/typeahead.service';
import { OlciAnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { AuthService } from './services/auth/auth.service';

@NgModule({
    imports: [
        CommonModule,
        LoggerModule,
        ErrorHandlerModule
    ],
    providers: [
        ConfigService,
        DScribeService,
        CookieServices,
        DataService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true
        },
        ReservationService,
        RoutingService,
        WindowRef,
        TypeaheadService,
        OlciAnalyticsService,
        AuthService
    ]
})
export class CoreModule { }
