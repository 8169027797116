import { Component, OnInit, Input } from '@angular/core';

import { SyndicatedOptions } from '@app/shared/components/syndicated-layout/syndicated-options.interface';
import { SYNDICATED_FOOTER_ANIMATIONS } from '@app/shared/components/syndicated-layout/syndicated-footer/syndicated-footer.animations';

@Component({
    selector: 'dtr-hub-finder-syndicated-aulani-footer',
    templateUrl: './syndicated-aulani-footer.component.html',
    styleUrls: ['./syndicated-aulani-footer.component.scss'],
    animations: [...SYNDICATED_FOOTER_ANIMATIONS]
})
export class SyndicatedAulaniFooterComponent implements OnInit {
    @Input() url: string;

    syndicatedFooterUrl: string;
    defaultOptions: SyndicatedOptions;
    useCached = false;
    stateName: string;
    showLine = false;

    constructor() { }

    ngOnInit() {
        this.syndicatedFooterUrl = this.url;
        this.stateName = 'loaded';
    }

    footerStateDone(event: AnimationEvent) {
        this.showLine = true;
        // We want to make sure that the animation is complete and the style has been set
        // by the animation module. Once that is done we can manually remove the style attr.
        setTimeout(() => {
            event['element'].removeAttribute('style');
        });
    }
}
