import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class TypeaheadService {
    focusOnNextMatch(e, list, dropdownValue, viewElement) {
        let curItem = _.find(list, (item) => {
            if (item.value) {
                return (item.value.charAt(0).toLowerCase() === e.key.toLowerCase());
            }
            return (item.charAt(0).toLowerCase() === e.key.toLowerCase());
        });
        if (curItem) {
            let nextItemInd = 0;
            const curSelectedItem = _.find(list, (item) => {
                nextItemInd = nextItemInd + 1;
                return (item.key ? item.key === dropdownValue : _.indexOf(list, item) === dropdownValue);
            });
            if (curSelectedItem && this.doesNextElementMatch(curSelectedItem, e, list, nextItemInd)) {
                viewElement.nativeElement.dispatchEvent(
                    new CustomEvent('item-selected', {
                        detail: {
                            value: list[nextItemInd].value || list[nextItemInd],
                            key: list[nextItemInd].key || list[nextItemInd]
                        }
                    })
                );
                return list[nextItemInd]['key'] || list[nextItemInd];
            } else {
                while (nextItemInd < list.length) {
                    const checkIfMatch = list[nextItemInd]['value'] ?
                        list[nextItemInd]['value'].charAt(0).toLowerCase() === e.key.toLowerCase() :
                        list[nextItemInd].charAt(0).toLowerCase() === e.key.toLowerCase();
                    if (checkIfMatch) {
                        curItem = list[nextItemInd];
                        break;
                    }
                    ++nextItemInd;
                }
                viewElement.nativeElement.shadowRoot
                    .querySelector(`div.custom-dropdown-container ul.options-list li[aria-label="${curItem.value || curItem}"]`)
                    .scrollIntoView();
                viewElement.nativeElement.dispatchEvent(
                    new CustomEvent('item-selected', {
                        detail: {
                            value: curItem.value || curItem,
                            key: curItem.key || curItem
                        }
                    })
                );
                return curItem.key || curItem;
            }
        }
        return null;
    }

    private doesNextElementMatch(curSelectedItem, e, list, nextItemInd): boolean {
        if (nextItemInd >= list.length) {
            return false;
        }
        const doesCurSelectedMatch = curSelectedItem.value ?
            curSelectedItem.value.charAt(0).toLowerCase() === e.key.toLowerCase()
            : curSelectedItem.charAt(0).toLowerCase() === e.key.toLowerCase();
        const doesNextItemMatch = list[nextItemInd]['value'] ?
            list[nextItemInd]['value'].charAt(0).toLowerCase() === e.key.toLowerCase()
            : list[nextItemInd].charAt(0).toLowerCase() === e.key.toLowerCase();
        return doesCurSelectedMatch && doesNextItemMatch;
    }
}
