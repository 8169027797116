import * as _ from 'lodash';
import { TravelPlanSegmentDetails } from './travel-plan-segment-details.model';
import { GeneralInfo } from './general-info.model';
import { PrearrivalInfo } from './pre-arrival-info.model';
import { SettlementMethods } from './settlement-methods.model';
import { ChargeAccountDetails } from './charge-account-details.model';
import { TravelPlanSegmentBands } from './travel-plan-segment-bands.model';
import { MdxAccountInfo } from './mdx-account-info.model';
import { Preferences } from './preferences.model';

export class Reservation {

    public static fromJSON(data: any): Reservation {
        return new Reservation(
            data.travelPlanSegmentDetails,
            data.generalInfo,
            data.prearrivalInfo,
            data.settlementMethods,
            data.chargeAccountDetails,
            data.travelPlanSegmentBands,
            data.mdxAccountInfo,
            data.preferences
        );

    }

    constructor(
        private _travelPlanSegmentDetails: TravelPlanSegmentDetails,
        private _generalInfo: GeneralInfo,
        private _prearrivalInfo: PrearrivalInfo,
        private _settlementMethods: SettlementMethods,
        private _chargeAccountDetails: ChargeAccountDetails,
        private _travelPlanSegmentBands: TravelPlanSegmentBands,
        private _mdxAccountInfo: MdxAccountInfo,
        private _preferences: Preferences
    ) {}

    public get travelPlanSegmentDetails(): TravelPlanSegmentDetails { return this._travelPlanSegmentDetails; }

    public get generalInfo(): GeneralInfo { return this._generalInfo; }

    public get prearrivalInfo(): PrearrivalInfo { return this._prearrivalInfo; }

    public get settlementMethods(): SettlementMethods { return this._settlementMethods; }

    public get chargeAccountDetails(): ChargeAccountDetails { return this._chargeAccountDetails; }

    public get travelPlanSegmentBands(): TravelPlanSegmentBands { return this._travelPlanSegmentBands; }

    public get mdxAccountInfo(): MdxAccountInfo { return this._mdxAccountInfo; }

    public get preferences(): Preferences { return this._preferences; }
}
