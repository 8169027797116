export const arrivalTimes = [
    {'key': '07:00:00', 'value': 'Before 7:00 AM'},
    {'key': '07:30:00', 'value': '7:30 AM'},
    {'key': '08:00:00', 'value': '8:00 AM'},
    {'key': '08:30:00', 'value': '8:30 AM'},
    {'key': '09:00:00', 'value': '9:00 AM'},
    {'key': '09:30:00', 'value': '9:30 AM'},
    {'key': '10:00:00', 'value': '10:00 AM'},
    {'key': '10:30:00', 'value': '10:30 AM'},
    {'key': '11:00:00', 'value': '11:00 AM'},
    {'key': '11:30:00', 'value': '11:30 AM'},
    {'key': '12:00:00', 'value': 'Noon'},
    {'key': '12:30:00', 'value': '12:30 PM'},
    {'key': '13:00:00', 'value': '1:00 PM'},
    {'key': '13:30:00', 'value': '1:30 PM'},
    {'key': '14:00:00', 'value': '2:00 PM'},
    {'key': '14:30:00', 'value': '2:30 PM'},
    {'key': '15:00:00', 'value': '3:00 PM'},
    {'key': '15:30:00', 'value': '3:30 PM'},
    {'key': '16:00:00', 'value': '4:00 PM'},
    {'key': '16:30:00', 'value': '4:30 PM'},
    {'key': '17:00:00', 'value': '5:00 PM'},
    {'key': '17:30:00', 'value': '5:30 PM'},
    {'key': '18:00:00', 'value': '6:00 PM'},
    {'key': '18:30:00', 'value': '6:30 PM'},
    {'key': '19:00:00', 'value': '7:00 PM'},
    {'key': '19:30:00', 'value': '7:30 PM'},
    {'key': '20:00:00', 'value': '8:00 PM'},
    {'key': '20:30:00', 'value': '8:30 PM'},
    {'key': '21:00:00', 'value': '9:00 PM'},
    {'key': '21:30:00', 'value': '9:30 PM'},
    {'key': '22:00:00', 'value': '10:00 PM'},
    {'key': '22:30:00', 'value': '10:30 PM'},
    {'key': '23:00:00', 'value': '11:00 PM'},
    {'key': '23:30:00', 'value': '11:30 PM'},
    {'key': '23:59:00', 'value': '11:59 PM'}
];
export const moduleId = 'resortArrivalTime';
export const dscribeArrivalDateRef = /\${arrivalDate}/gm;
export const dscribeResortArrivalTimeRef = /\${standarResortArrivalTime}/gm;
