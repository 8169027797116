import { Component, OnInit, Input } from '@angular/core';
import { RRN_CONSTANTS } from './room-ready-notification.component.constants';
import { MdxAccountInfo } from '@app/core/models/mdx-account-info.model';
import { PrearrivalInfo } from '@app/core/models/pre-arrival-info.model';
import {ReservationService} from '@core/services/reservation.service';
import * as rrn_CONSTANTS from './room-ready-notification.component.constants';
import * as _ from 'lodash';
import { GlobalThemeService } from '../services/global-theme-service/global.theme.service';
import { commonId } from 'app/core/constants/common-constants';
import { GeneralInfo } from '../core/models/general-info.model';
import { addAnalyticsTrackLinkToATag } from '@app/shared/utils/utils.object';
import { ANALYTICS, ANALYTICS_TRACK_LINKS } from '@app/shared/constants/app.constants';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'dtr-hub-room-ready-notification',
    templateUrl: './room-ready-notification.component.html',
    styleUrls: ['./room-ready-notification.component.scss']
})

export class RoomReadyNotificationComponent implements OnInit {

    UI_CONSTANTS: any = {};
    readMoreExpanded = false;
    @Input() campus;
    @Input() collapsed;
    @Input() isAddFlow;
    @Input() dscbDetails;
    commonData: any;

    private _mdxAccountInfo: MdxAccountInfo;
    private _preArrivalInfo: PrearrivalInfo;
    private _generalInfo: GeneralInfo;

    @Input() set mdxAccountInfo(value) {
        this._mdxAccountInfo = value;
    }

    @Input() set preArrivalInfo(value) {
        this._preArrivalInfo = value;
    }

    @Input() set generalInfo(value) {
        this._generalInfo = value;
    }

    constructor(
        public reservationService: ReservationService,
        public globalThemeService: GlobalThemeService,
        public sanitizer: DomSanitizer
    ) {}

    isMobileValid = true;
    isEmailValid = true;
    email: string;
    mobile: string;
    isEditFlow = false;
    rrnData: any;

    private rrnTermsConditions: string;

    private initializeConstants() {
        this.UI_CONSTANTS = RRN_CONSTANTS;
    }

    ngOnInit() {
        this.initializeConstants();
        this.initializeEmailAndMobile();
        this.reservationService.setRRNSaveSet(true);
        this.rrnData = _.find(this.dscbDetails, function(module) {
            return module.id === rrn_CONSTANTS.moduleId;
        });
        this.commonData = _.find(this.dscbDetails, function(module) {
            return module.id === commonId;
        });
    }

    initializeEmailAndMobile() {
        this.email = this._preArrivalInfo.emailAddress ? this._preArrivalInfo.emailAddress : this._mdxAccountInfo.email;
        this.mobile = this._preArrivalInfo.mobilePhoneNumber ? this._preArrivalInfo.mobilePhoneNumber : '';
        this.updateRoomcheckinPayload();
    }

    validateMobile(e) {
        this.mobile = e.target.value;
        const regexpMobile = new RegExp('^$|^[ ]*\\(?[2-9]\\d{2}\\)? ?[-.]?\\d{3}[ -.]?\\d{4}[ ]*$');
        this.isMobileValid = !this.mobile || this.mobile === '' || regexpMobile.test(this.mobile);
        if (this.isMobileValid) {
            this.updateRoomcheckinPayload();
        } else {
            this.reservationService.setRRNSaveSet(false);
        }
    }

    validateEmail(e) {
        this.email = e.target.value;
        const regexpEmail = new RegExp('^$|^([a-zA-Z0-9\\-_]+)(\\.[a-zA-Z0-9\\-_\\+]+)*@([a-zA-Z0-9\\-_]+)' +
            '(\\.[a-zA-Z0-9\\-_]+)*(\\.[a-zA-Z]{2,4})$');
        this.isEmailValid = !this.email || this.email === '' || regexpEmail.test(this.email);
        if (this.isEmailValid) {
            this.updateRoomcheckinPayload();
        } else {
            this.reservationService.setRRNSaveSet(false);
        }
    }

    updateRoomcheckinPayload() {
        const payload = this.reservationService.roomcheckinPayload;
        payload.prearrivalInfo.mobilePhoneNumber = this.mobile ? this.mobile.replace(/\D/g, '') : null;
        payload.prearrivalInfo.emailAddress = this.email ? this.email : null;
        payload.prearrivalInfo.mobilePush = this._mdxAccountInfo ?
                this._mdxAccountInfo.swid :
                null;
    }

    get getMobileAccessibilityText() {
        const tempMobile = this.mobile ? this.mobile + ', ' : '';
        if (this.isMobileValid) {
            return this.rrnData.descriptions[0].sections.phoneAccesability.replace('value', tempMobile);
        } else {
            return this.rrnData.descriptions[1].sections.InvalidMobileAltText.replace('value', tempMobile);
        }
    }
    get getEmailAccessibilityText() {
        const tempEmail = this.email ? this.email + ', ' : '';
        if (this.isEmailValid) {
            return this.rrnData.descriptions[0].sections.emailAccesability.replace('value', tempEmail);
        } else {
            return this.rrnData.descriptions[1].sections.InvalidEmailAltText.replace('value', tempEmail);
        }
    }
    get getHeadingAccessibilityText() {
        return this.rrnData.sections.titleAccesability;
    }
    get getRrnHeading() {
        return this._generalInfo.dtrTermsConditionsTitle;
    }
    get getRrnTermsAndConditionsWDW() {
        if (this.rrnTermsConditions && this.rrnTermsConditions.length > 0) {
            // We save the RRN Terms Conditions to the component so we don't have rebuild it everytime
            // angular is auto-sanitizing the contents, which removes the analyticsTrackLink
            return this.sanitizer.bypassSecurityTrustHtml(this.rrnTermsConditions);
        }
        const termsAndConditionsBody = this._generalInfo.dtrTermsConditions;
        const trackLinkConfig = ANALYTICS_TRACK_LINKS.filter((conf) => {
            return conf.element === 'rrnTermsConditions';
        })[0];
        const updatedTermsAndConditions = addAnalyticsTrackLinkToATag(termsAndConditionsBody, trackLinkConfig, ANALYTICS);
        this.rrnTermsConditions = updatedTermsAndConditions;
        // angular is auto-sanitizing the contents, which removes the analyticsTrackLink
        return this.sanitizer.bypassSecurityTrustHtml(this.rrnTermsConditions);
    }
    get getRrnTermsAndConditionsAulani() {
        // We don't need the above for Aulani, as we aren't enabling Aulani analytics at this time..
        return this._generalInfo.dtrTermsConditions;
    }
    // This is now a string isntead of an array, so these will be put into an array
    get getInvalidMobileText() {
        return [
            this.rrnData.descriptions[1].sections.InvalidMobileText
        ];
    }
    // This is now a string isntead of an array, so these will be put into an array
    get getInvalidEmailText() {
        return [
            this.rrnData.descriptions[1].sections.InvalidEmailText
        ];
    }
    // This is now a string isntead of an array, so these will be put into an array
    get getInvalidMobileEmailText() {
        return [
            this.rrnData.descriptions[1].sections.InvalidMobileEmailText
        ];
    }
    // This is now two separate values, so these will be put into an array
    get getMobileInfoText() {
        return [
            this.rrnData.descriptions[1].sections.MobileInfoTextAvailability,
            this.rrnData.descriptions[1].sections.MobileInfoTextRates
        ];
    }
    get getAulaniMobileInfoText() {
        return [
            this.rrnData.descriptions[1].sections.MobileInfoTextAvailability,
            this.rrnData.descriptions[1].sections.MobileInfoTextRates
        ];
    }
    get getMobileLabel() {
        return this.rrnData.descriptions[0].sections.phone;
    }
    get getEmailLabel() {
        return this.rrnData.descriptions[0].sections.email;
    }
    get getInvalidMobileAccessibilityText() {
        return this.rrnData.descriptions[1].sections.InvalidMobileAltText;
    }
    get getInvalidEmailAccessibilityText() {
        return this.rrnData.descriptions[1].sections.InvalidEmailAltText;
    }
    get getInvalidMobileEmailAccessibilityText() {
        return this.rrnData.descriptions[1].sections.InvalidMobileEmailAltText;
    }
    get getRrnReadMoreText() {
        return this.rrnData.sections.readMore;
    }
    get getRrnReadMoreTextAlt() {
        return this.rrnData.sections.readMoreAccessibility;
    }
    get getRrnReadLessText() {
        return this.rrnData.sections.readLess;
    }
    get getRrnReadLessTextAlt() {
        return this.rrnData.sections.readLessAccessibility;
    }
    get getRrnReadMoreBody() {
        return this._generalInfo.dtrTermsConditionsMore;
    }
    get getRrnSendEmailToText() {
        return this.rrnData.sections.editEmail;
    }
    get getRrnSendTextToText() {
        return this.rrnData.sections.editText;
    }
    get getRrnSendEmailToAltText() {
        return this.rrnData.sections.editEmail + this.email;
    }
    get getRrnSendTextToAltText() {
        return this.rrnData.sections.editText + this.mobile;
    }
    get getRrnEdit() {
        return this.commonData.descriptions[0].sections.editLabel;
    }
    get getRrnEditAlt() {
        return this.commonData.descriptions[0].sections.editLabelAccesability;
    }

    get isGeneralInfoReady() {
        return this._generalInfo;
    }
}
