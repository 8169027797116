import { Injectable, InjectionToken, Injector } from '@angular/core';
import { DataService } from './data/data.service';
import { Observable , of } from 'rxjs';

@Injectable()
export class DScribeService {
    dscribeContent: any;
    olciStaticDetails: any;
    selectResStaticContent: any;

    constructor(
        public dataService: DataService
    ) { }

    getDScribeDetails(): Observable<any> {
        if (this.dscribeContent) {
            return of(this.dscribeContent);
        } else {
            return this.dataService.getDScribeContent();
        }
    }

    setDScribeDetails(content: any) {
        this.dscribeContent = content;
    }

    setOlciStaticDetails(content: any) {
        this.olciStaticDetails = content;
    }

    getOlciStaticDetails(facilityId: number): Observable<any> {
        if (this.olciStaticDetails) {
            return of(this.olciStaticDetails);
        } else if (facilityId) {
            return this.dataService.getOlciStaticContent(facilityId);
        } else {
            return this.dataService.getOlciStaticContent(1);
        }
    }

    setSelectResStaticDetails(content: any) {
        this.selectResStaticContent = content;
    }

    getSelectResStaticDetails(facilityId: number): Observable<any> {
        if (this.selectResStaticContent) {
            return of(this.selectResStaticContent);
        } else if (facilityId) {
            return this.dataService.getOlciStaticContent(facilityId);
        } else {
            return this.dataService.getOlciStaticContent(1);
        }
    }
}
