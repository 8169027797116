export class SessionDetails {

    public static fromJSON(data: any): SessionDetails {

        return new SessionDetails(
            data.sessionToken,
            data.hmac,
            data.oauth
        );
    }

    constructor(
        private _sessionToken: string,
        private _hmac: string,
        private _oauth: string
    ) {}

    public get sessionToken(): string { return this._sessionToken; }

    public get hmac(): string { return this._hmac; }

    public get oauth(): string { return this._oauth; }
}
