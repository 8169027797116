import { Component, HostBinding, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { TNC_CONSTANTS } from '@app/tnc-page/tnc-page.component.constants';
import { GlobalThemeService } from '@app/services/global-theme-service/global.theme.service';
import { RoutingService } from '@core/services/routing.service';
import * as tncConstants from './tnc-page.component.constants';
import { ConfigService } from '../core/config.service';
import * as _ from 'lodash';
import * as commonConstants from '@app/core/constants/common-constants';
import {ReservationService} from '@core/services/reservation.service';
import { Router } from '@angular/router';
import { petRouteURLs } from '@app/core/constants/common-constants';

@Component({
    selector: 'dtr-hub-tnc-page',
    templateUrl: './tnc-page.component.html',
    styleUrls: ['./tnc-page.component.scss']
})

export class TncPageComponent implements OnInit, OnDestroy {
    UI_CONSTANTS: any = {};
    campus: string;

    @HostBinding('class.aulani') aulani: boolean = this.globalThemeService.getCampus() === 'aulani';
    @HostBinding('class.wdw') wdw: boolean = this.globalThemeService.getCampus() === 'wdw';

    wdwHeaderURL: string;
    wdwFooterURL: string;
    wrapperId: string;
    aulaniURL: string;
    config: any;
    dscribeDetails: any;
    termsAndConditionsBody: any;
    errorResponse: any;
    lang: string;
    isPetTnc: boolean;

    constructor(
        public globalThemeService: GlobalThemeService,
        public routingService: RoutingService,
        public configService: ConfigService,
        public reservationService: ReservationService,
        private router: Router
    ) {
        this.initializeConstants();
        this.config = this.configService.config;
    }

    ngOnInit() {
        this.wrapperId = tncConstants.wrapperId;
        const hostName = this.globalThemeService.getHostName() === 'localhost' ?
            'latest.disneyworld.disney.go.com' : this.globalThemeService.getHostName();
        this.wdwHeaderURL = tncConstants.protocol + hostName + tncConstants.headerURL;
        this.wdwFooterURL = tncConstants.protocol + hostName + tncConstants.footerURL;
        this.campus = this.globalThemeService.getCampus();
        this.aulaniURL = this.config.aulaniContentURL;
        this.getDScribeDetailsForTnC();
        const currentUrl = this.router.url;
        if (currentUrl.includes(petRouteURLs.PET_TNC)) {
            this.isPetTnc = true;
            this.getPetTermsAndConditions();
        } else {
            this.getTermsAndConditions();
        }
    }

    getPetTermsAndConditions() {
        this.termsAndConditionsBody = localStorage.getItem(commonConstants.olciPetTncLocalStorageName);
    }

    getDScribeDetailsForTnC() {
        this.dscribeDetails = JSON.parse(localStorage.getItem(commonConstants.olciTncStaticContentLocalStorageName));
    }

    getTermsAndConditions() {
        this.termsAndConditionsBody = localStorage.getItem(commonConstants.olciTncLocalStorageName);
    }

    routeForError(error) {
        this.errorResponse = error;
        const routeURL = commonConstants.routeURLs.SLASH +
            commonConstants.routeURLs.BASE_URL +
            commonConstants.routeURLs.ERROR;
        this.routingService.routeToRouteURL(routeURL, false);
    }

    breadcrumbsCallback(url) {
        if (url === '/online-check-in') {
            this.routingService.routeToRouteURL('/', false);
        } else if (url === '/plan') {
            this.routingService.redirectToReturnURL();
        } else {
            this.routingService.redirectToHomeURL();
        }

        return false;
    }

    private initializeConstants() {
        this.UI_CONSTANTS = TNC_CONSTANTS;
    }

    print() {
        this.globalThemeService.getWindow().print();

        return false;
    }

    get getTncAltMainHeading() {
        // TODO This feels wrong..title doesn't exist in the Dscribe response.
        // It was specifically requested to be removed..
        return tncConstants.TNC_CONSTANTS.tncMainHeading;
    }

    get getTncMainHeading() {
        return this.isPetTnc ? localStorage.getItem(commonConstants.olciPetTncTitleLocalStorageName) :  tncConstants.TNC_CONSTANTS.tncMainHeading;
    }

    get getTncSubHeading() {
        return this.isPetTnc ? localStorage.getItem(commonConstants.olciPetTncSubTitleLocalStorageName) : tncConstants.TNC_CONSTANTS.tncSubHeading;
    }

    // TODO, not sure where this is supposed to come from..
    get getTncParaBoldPart() {
        return this.dscribeDetails[1].sections.campus;
    }

    get getTncFullBody() {
        return this.termsAndConditionsBody;
    }

    get getTncPrintText() {
        return this.dscribeDetails[0].sections.printLabel;
    }

    get getTncBreadcrumbsUrls() {
        const planBreadcrumbName = this.campus === 'wdw' ?
            this.dscribeDetails[1].sections.breadCrumb2 : this.dscribeDetails[1].sections.breadCrumb2;

        return [{ 'icon': 'di-home', 'name': this.dscribeDetails[1].sections.breadCrumb1, 'url': '/' },
        { 'name': planBreadcrumbName, 'url': '/plan' },
        { 'name': this.dscribeDetails[1].sections.breadCrumb3, 'url': '/online-check-in' }];
    }

    ngOnDestroy(): void {
        localStorage.removeItem(commonConstants.olciTncLocalStorageName);
        localStorage.removeItem(commonConstants.olciTncStaticContentLocalStorageName);
    }
}
