import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, Injector, DoBootstrap } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CoreModule } from '@core/core.module';
import { ConfigService } from '@core/config.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutOlciComponent } from './about-olci/about-olci.component';
import { RoomReadyNotificationComponent } from './room-ready-notification/room-ready-notification.component';
import { GlobalThemeService } from './services/global-theme-service/global.theme.service';
import { CorrelationModule } from '@wdpr/ra-angular-correlation';
import { ResortArrivalComponent } from './resort-arrival/resort-arrival.component';
import { HotelAccountPinComponent } from './hotel-account-pin/hotel-account-pin.component';
import { RoomLocationRequestComponent } from '@app/room-location-request/room-location-request.component';
import { ReservationAddressComponent } from '@app/reservation-address/reservation-address.component';
import { ServiceYourWayComponent } from '@app/service-your-way/service-your-way.component';
import { DetailsOnFileComponent } from '@app/details-on-file/details-on-file.component';
import { SharedModule } from '@app/shared/shared.module';
import { ReservationDetailsComponent } from './reservation-details/reservation-details.component';
import { DatePipe } from '@angular/common';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { DtrRequestService } from './core/services/dtr-request/dtr-request.service';
import { WindowRef } from '@app/shared/services/window-ref/window-ref.service';
import { WINDOW_PROVIDERS } from '@app/services/global-theme-service/window.provider';
import { DOCUMENT_PROVIDERS } from '@app/services/global-theme-service/document.provider';
import { TncPageComponent } from './tnc-page/tnc-page.component';
import { MagicBandComponent } from './magic-band/magic-band.component';
import { ErrorBannerComponent } from './error-banner/error-banner.component';
import { LoaderComponent } from './loader/loader.component';
import { ScriptLoaderComponent } from '@app/shared/components/script-loader/script-loader.component';
import {
    SyndicatedLegalFooterComponent
} from '@app/shared/components/syndicated-layout/syndicated-legal-footer/syndicated-legal-footer.component';
import { SyndicatedFooterComponent } from '@app/shared/components/syndicated-layout/syndicated-footer/syndicated-footer.component';
import {
    SyndicatedAulaniFooterComponent
} from './shared/components/syndicated-layout/syndicated-aulani-footer/syndicated-aulani-footer.component';
import { SyndicatedHeaderComponent } from './shared/components/syndicated-layout/syndicated-header/syndicated-header.component';
import {
    SyndicatedAulaniHeaderComponent
} from './shared/components/syndicated-layout/syndicated-aulani-header/syndicated-aulani-header.component';
import { HotelAccountChargesComponent } from './hotel-account-charges/hotel-account-charges.component';
import { PageKeyModule, PageKeyService } from '@wdpr/ra-angular-page-key';
import { LoggerService, LogasaurusService } from '@wdpr/ra-angular-logger';
import { AuthService } from '@core/services/auth/auth.service';
import { DateTimeZonePipe } from './pipes/date-time-zone.pipe';
import { PetRegistrationComponent } from './pet-registration/pet-registration.component';

@NgModule({ declarations: [
        AppComponent,
        HomeComponent,
        AboutOlciComponent,
        DetailsOnFileComponent,
        RoomLocationRequestComponent,
        RoomReadyNotificationComponent,
        ResortArrivalComponent,
        HotelAccountPinComponent,
        ReservationDetailsComponent,
        ReservationAddressComponent,
        ServiceYourWayComponent,
        TermsConditionComponent,
        TncPageComponent,
        MagicBandComponent,
        ErrorBannerComponent,
        ScriptLoaderComponent,
        SyndicatedFooterComponent,
        SyndicatedLegalFooterComponent,
        SyndicatedAulaniFooterComponent,
        SyndicatedHeaderComponent,
        SyndicatedAulaniHeaderComponent,
        LoaderComponent,
        HotelAccountChargesComponent,
        DateTimeZonePipe,
        PetRegistrationComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [AppRoutingModule,
        BrowserModule,
        CoreModule,
        CorrelationModule,
        SharedModule,
        BrowserAnimationsModule,
        PageKeyModule], providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (config: ConfigService) => () => {
                return config
                    .load()
                    .then((result: any) => {
                    return result;
                })
                    .catch(err => {
                    return err;
                });
            },
            deps: [ConfigService],
            multi: true
        },
        DtrRequestService,
        WINDOW_PROVIDERS,
        DOCUMENT_PROVIDERS,
        WindowRef,
        [GlobalThemeService],
        {
            provide: 'CORRELATION_CONFIG',
            useValue: {
                wdprConversationCookieName: 'Conversation_UUID',
                wdprConversationHeader: 'x-conversation-id',
                wdprCorrelationHeader: 'x-correlation-id',
                wdprEndpointFilter: '/trip/online-check-in/error/?'
            }
        },
        DatePipe,
        DateTimeZonePipe,
        // Not sure if this is needed
        {
            provide: 'PAGE_KEY_ADDITIONAL_CONFIG',
            useValue: {
                // We want to get siteId and locale from global theme service, i think..
                siteId: 'wdw',
                locale: 'en',
                site: 'site'
            }
        },
        {
            provide: 'PAGE_KEY_TEMPLATE',
            useValue: [
                'siteId', 'site', 'locale', 'application'
            ]
        },
        {
            provide: 'PAGE_KEY_URL_ENDPOINT',
            useValue: '/trip/online-check-in/server/page-key/'
        },
        {
            provide: 'PAGE_KEY_REMOTE_CALL_FLAG',
            useValue: true
        },
        LoggerService,
        PageKeyService,
        LogasaurusService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule implements DoBootstrap{
    private config: any;

    constructor(
        private configService: ConfigService,
        private injector: Injector,
    ) { }

    ngDoBootstrap(app) {
        this.config = this.configService.config;
        const globalThemeService = this.injector.get(GlobalThemeService);
        const authService = this.injector.get(AuthService);
        // const campus = globalThemeService.getCampus();
        const campus = globalThemeService.getCampusBasedOnHost();
        const protocol = 'https://';
        const host = globalThemeService.getHostName();
        let url = protocol + host;
        // Uncomment to test on local
        if (host === 'localhost') {
            url = url + ':8626';
        }

      if (campus!='wdw') {
        authService.redirectToLightboxLogin().then(userData => {
          authService.swid = userData.guest.profile.swid;
          authService.accessToken = userData.guest.token.access_token;
          this.bootstrapRootComponent(app, 'dtr-hub-root');
        });
      } else {
        this.bootstrapRootComponent(app, 'dtr-hub-root');
      }
    }

    bootstrapRootComponent(app, name) {
        const componentMap = {
            'dtr-hub-root': AppComponent
        };
        const componentElement = document.createElement(name);
        document.body.appendChild(componentElement);
        const component = componentMap[name];
        app.bootstrap(component);
    }
}
