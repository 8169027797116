import { Injectable, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WINDOW } from './window.provider';
import { ConfigService } from '../../core/config.service';
import { DOCUMENT } from './document.provider';

@Injectable()
export class GlobalThemeService {
    campus: string;
    returnUrl: string;
    hostName: string;
    port: string;
    langLocale: string;
    config: any;

    constructor(
        public route: ActivatedRoute,
        @Inject(WINDOW) private window: any,
        @Inject(DOCUMENT) private document: any,
        public configService: ConfigService,
    ) {
        this.setCampus();
        this.setReturnURL();
        this.setHostName();
        this.setHostPort();
        this.config = this.configService.config;
    }
    getCampus() {
        // If the hostname tells us we are on DVC...we want to use the query param logic to get the campus.
        // this is for themeing purporses from the DVC site
        if ((this.config && this.config.overrideCampusEnabled) || this.getCampusBasedOnHost() === 'dvc') {
            return this.campus === 'wdw' ? 'wdw' : 'aulani';
        } else {
            return this.hostName.includes('disneyworld') ? 'wdw' : 'aulani';
        }
    }

    getCampusClient(isBeach?) {
        if (isBeach) {
            return isBeach;
        } else {
            return this.getCampus();
        }
    }

    getCampusParam() {
        return this.campus;
    }

    setCampus(): void {
      const queryParams = new URLSearchParams(this.window.location.search);
      this.campus = queryParams.get('campus');
    }

    getReturnURL() {
        return this.returnUrl || this.config.returnURL[this.getCampus()];
    }

    setReturnURL(): void {
        this.route.queryParams.subscribe(params => {
            this.returnUrl = params['returnUrl'];
        });
    }

    getHostName() {
        return this.hostName;
    }

    setHostName(): void {
        this.hostName = this.window.location.hostname;
    }

    getHostPort() {
        return this.port;
    }

    setHostPort(): void {
        this.port = this.window.location.port;
    }

    getWindow() {
        return this.window;
    }

    getDocument() {
        return this.document;
    }

    getRequestedLanguage() {
        return this.langLocale;
    }

    setRequestedLanguage(langLocale: string): void {
        this.langLocale = langLocale;
    }

  getCampusBasedOnHost(): string {
      return this.campus || this.hostName.includes('disneyworld') ?
      'wdw' : this.hostName.includes('disneyvacationclub') ?
        'dvc' : 'aulani';
  }

    getAPPClientHeader(isBeach?): string {
        if (isBeach) {
            return isBeach;
        } else {
            return this.getCampus() === 'wdw' ? 'wdw-web' : 'aul-web';
        }
    }
}
