import { Component, Input, OnInit } from '@angular/core';
import * as UI_CONSTANTS from '@app/pet-registration/pet-registration.constants';
import * as _ from 'lodash';
import { commonId, olciPetTncLocalStorageName, olciPetTncSubTitleLocalStorageName, olciPetTncTitleLocalStorageName, olciTncStaticContentLocalStorageName, petRouteURLs } from 'app/core/constants/common-constants';
import { ReservationService } from '@app/core/services/reservation.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ANALYTICS, ANALYTICS_TRACK_LINKS } from '@app/shared/constants/app.constants';
import { addAnalyticsTrackLinkToATag } from '@app/shared/utils/utils.object';
import { RoutingService } from '@app/core/services/routing.service';
import { petPolicyTermsConditionsBody } from '@app/terms-condition/terms-condition.component.constants';
import { DScribeService } from '@app/core/services/dscribe.service';
@Component({
  selector: 'dtr-hub-app-pet-registration',
  templateUrl: './pet-registration.component.html',
  styleUrls: ['./pet-registration.component.scss']
})
export class PetRegistrationComponent implements OnInit {
  @Input() campus;
  @Input() isAddFlow;
  @Input() reservation;
  @Input() saveInProgress;
  @Input() dscbDetails;
  @Input() collapsed;

  UI_CONSTANTS;
  petRegistrationData: any;
  commonData: any;
  isPetSectionOpen: boolean;
  petCount: any;
  edit = false;

  private learnMoreLinkText: string;

  constructor(
    public routingService: RoutingService,
    public dscribeService: DScribeService,
    public reservationService: ReservationService,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.UI_CONSTANTS = UI_CONSTANTS;

    this.petRegistrationData = _.find(this.dscbDetails, function (module) {
      return module.id === UI_CONSTANTS.moduleId;
    });
    this.commonData = _.find(this.dscbDetails, function (module) {
      return module.id === commonId;
    });

    this.petCount =
    this.isPetInfoAvailable(this.reservationService.roomcheckinPayload)
      ? this.reservationService.roomcheckinPayload.preferences.petInfo.petCount
      : this.minPetCount;

    if (this.petCount > 0) { this.openPetSection(); }
    this.reservationService.petCount = this.petCount;
  }

  isPetInfoAvailable = (payload: any): boolean => {
    return (
      payload &&
      payload.preferences &&
      payload.preferences.petInfo &&
      payload.preferences.petInfo.petCount &&
      payload.preferences.petInfo.petCount > 0
    );
  };

  openPetSection() {
    this.isPetSectionOpen = true;
  }

  decreasePetCount() {
    this.petCount = this.petCount === this.minPetCount ? this.minPetCount : this.petCount - 1;
    this.reservationService.roomcheckinPayload.preferences = {
      petInfo: {
        petCount: this.petCount,
      }
    };
    this.reservationService.petCount = this.petCount;
    if (this.petCount === 0) {
      // As when petcount is 0 we don't need to check TnC
      this.reservationService.setPetTnCSaveSet(true);
    }
  }

  increasePetCount() {
    this.petCount = this.petCount === this.maxPetCount ? this.maxPetCount : this.petCount + 1;
    this.reservationService.roomcheckinPayload.preferences = {
      petInfo: {
        petCount: this.petCount,
      }
    };
    this.reservationService.petCount = this.petCount;
  }

  redirectToLinkForPet() {

    const dscribeSub = this.dscribeService.getOlciStaticDetails(this.reservationService.facilityId)
      .subscribe({
        next: (res) => {
          const dscribeDetails = _.filter(res.body.modules, function (module) {
            return module.id === UI_CONSTANTS.moduleIdTNC;
          })[0].modules;
          localStorage.setItem(olciPetTncLocalStorageName,
            this.reservationService.roomcheckinPayload.generalInfo.petPolicyTermsConditionsBody);
          localStorage.setItem(olciPetTncSubTitleLocalStorageName,
            this.reservationService.roomcheckinPayload.generalInfo.petPolicyTermsConditionsSubTitle);
          localStorage.setItem(olciPetTncTitleLocalStorageName,
            this.reservationService.roomcheckinPayload.generalInfo.petPolicyTermsConditionsTitle);
          localStorage.setItem(olciTncStaticContentLocalStorageName,
            JSON.stringify(dscribeDetails));
          const routeURL = petRouteURLs.BASE_URL +
            petRouteURLs.PET_TNC;
          this.routingService.routeToRouteURL(routeURL, true);
          return false;
        },
        error: (error) => {
          console.log('error: ', error);
        }
  });
    return false;

  }
  // All get methods

  get petRegistrationHeading(): string {
    return this.petRegistrationData.sections.title;
  }

  get petRegistrationDescription(): string {
    return this.petRegistrationData.sections.description;
  }

  get petRegistrationExpandedDescription(): string {
    return this.petRegistrationData.descriptions[0].sections.description.replace("{{maxPetCount}}", this.maxPetCount);
  }

  get petRegistrationAdd(): string {
    return this.commonData.descriptions[0].sections.addLabel;
  }

  get petRegistrationAddText(): string {
    return this.commonData.descriptions[0].sections.addLabel;
  }

  get learnMoreLink() {
    if (this.learnMoreLinkText && this.learnMoreLinkText.length > 0) {
      // We save the RRN Terms Conditions to the component so we don't have rebuild it everytime
      // angular is auto-sanitizing the contents, which removes the analyticsTrackLink
      return this.sanitizer.bypassSecurityTrustHtml(`<strong>${this.learnMoreLinkText}</strong>`);
    }
    const termsAndConditionsBody = this.petRegistrationData.descriptions[0].sections.learnMoreLinkText;
    const trackLinkConfig = ANALYTICS_TRACK_LINKS.filter((conf) => {
      return conf.element === 'rrnTermsConditions';
    })[0];
    const updatedTermsAndConditions = addAnalyticsTrackLinkToATag(termsAndConditionsBody, trackLinkConfig, ANALYTICS);
    this.learnMoreLinkText = updatedTermsAndConditions;
    // angular is auto-sanitizing the contents, which removes the analyticsTrackLink
    return this.sanitizer.bypassSecurityTrustHtml(`<strong>${this.learnMoreLinkText}</strong>`);
  }

  get petCountText(): string {
    return this.petRegistrationData.descriptions[0].sections.petCount;
  }

  get minPetCount(): string {
    const minPetCount = this.petRegistrationData.descriptions[0].sections.minPetCount;
    return typeof minPetCount === 'string' ? Number(minPetCount) : minPetCount;
  }

  get maxPetCount(): string {
    const maxPetCount = this.petRegistrationData.descriptions[0].sections.maxPetCount;
    return typeof maxPetCount === 'string' ? Number(maxPetCount) : maxPetCount;
  }

  get petAgreementLinkText(): string {
    return this.petRegistrationData.descriptions[0].sections.petAgreementLinkText;
  }

  get serviceAnimalInfoText(): string {
    return this.petRegistrationData.descriptions[0].sections.serviceAnimalInfoText;
  }

}
