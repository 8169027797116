export const ABOUT_OLCI_CONSTANTS = {
aoHeading: 'We Just Need a Little More Information',
aoHeadingAltText: 'We Just Need a Little More Information',
aoDescription: 'Want to skip the Front Desk? Submit the requested information, order your MagicBand and bring' +
    'it with you, and complete hotel check-in when you go directly to your room.If you won’t have a MagicBand when you arrive,' +
    'completing this information will speed up your time at the Front Desk. MagicBand orders shipped to U.S. addresses only.',
aoRequiredField: '*required field',
aoOlciHeading: 'Online Check-In Service',
aoOlciHeadingAltText: 'Online Check-In Service',
aoAulaniDescription: 'Thanks for using Online Check-In service! Should your plans change, ' +
    'feel free to update any of the details.',
aoDescriptionDOF: 'Thanks for using Online Check-In service! Should your plans change, feel free to update any of the details.'
};
export const moduleId = 'informationSection';
export const oclimoduleId = 'olciHeader';
