import * as _ from 'lodash';
import { ResortReservation } from './resort-reservation.model';

export class ReservationSummary {

    public static fromJSON(data: any): ReservationSummary {
        const resortReservations = Array.isArray(data.resortReservations) ?
            data.resortReservations.map(entry => ResortReservation.fromJSON(entry)) : [];

        return new ReservationSummary(
            data.startItem,
            data.itemLimit,
            data.itemCount,
            resortReservations
        );

    }

    constructor(
        private _startItem: number,
        private _itemLimit: number,
        private _itemCount: number,
        private _resortReservations: ResortReservation[],
    ) {}

    public get startItem(): number { return this._startItem; }

    public get itemLimit(): number { return this._itemLimit; }

    public get itemCount(): number { return this._itemCount; }

    public get resortReservations(): ResortReservation[] { return this._resortReservations; }
}
