import { Component, OnInit, Input } from '@angular/core';
import { brandFooterDefaultOptions } from '@app/shared/components/syndicated-layout/syndicated-options';
import { SyndicatedOptions } from '@app/shared/components/syndicated-layout/syndicated-options.interface';
import { SYNDICATED_FOOTER_ANIMATIONS } from '@app/shared/components/syndicated-layout/syndicated-footer/syndicated-footer.animations';
import * as _ from 'lodash';

@Component({
    selector: 'dtr-hub-finder-syndicated-footer',
    templateUrl: './syndicated-footer.component.html',
    styleUrls: ['./syndicated-footer.component.scss'],
    animations: [...SYNDICATED_FOOTER_ANIMATIONS]
})
export class SyndicatedFooterComponent implements OnInit {
    @Input() url: string;
    @Input() wrapperId: string;

    syndicatedFooterUrl: string;
    defaultOptions: SyndicatedOptions;
    useCached = false;
    stateName: string;
    showLine = false;

    constructor() { }

    ngOnInit() {
        this.defaultOptions = { ...brandFooterDefaultOptions['wdw'] };
        this.syndicatedFooterUrl = this.url;
        this.stateName = 'loaded';
    }

    footerStateDone(event: AnimationEvent) {
        this.showLine = true;
        // We want to make sure that the animation is complete and the style has been set
        // by the animation module. Once that is done we can manually remove the style attr.
        setTimeout(() => {
            event['element'].removeAttribute('style');
            if ((document.querySelector('.subSection') as HTMLElement)) {
                (document.querySelector('.subSection') as HTMLElement).style.height = '190px';
                (document.querySelector('.drcCta') as HTMLElement).style.paddingTop = '0';
                const footerDiv = (document.querySelector('.wdwFooter') as HTMLElement);
                (footerDiv.querySelector('.horizontalSeparator') as HTMLElement).style.display = 'none';
                const triggerContainer = document.querySelector('.triggerContainer') as HTMLElement;
                _.forEach(triggerContainer.querySelectorAll('.icon__norgie-closed'), function (htmlElemnt) {
                    htmlElemnt.classList.add('di');
                    htmlElemnt.classList.add('di-norgie-closed');
                    htmlElemnt.style.fontSize = '20px';
                });
            }
        }, 3000);
    }
}
