import { Component, OnInit, Input } from '@angular/core';
import { TravelPlanSegmentBands } from '@app/core/models/travel-plan-segment-bands.model';
import * as constants from './magic-band.component.constants';
import {ReservationService} from '@core/services/reservation.service';
import * as _ from 'lodash';

@Component({
    selector: 'dtr-hub-magic-band',
    templateUrl: './magic-band.component.html',
    styleUrls: ['./magic-band.component.scss']
})
export class MagicBandComponent implements OnInit {
    @Input() campus;
    @Input() collapsed;
    @Input() isAddFlow;
    @Input() dscbDetails;
    @Input() allSectionsOnFile;
    magicBandData: any;
    optedOut: boolean;
    optOutAvailable: boolean;
    mbError: boolean;
    pickUpMB: boolean;
    bringMB: boolean;
    mbDecline: boolean;
    guessBring: boolean;
    private _travelPlanSegmentBands: TravelPlanSegmentBands;

    @Input() set travelPlanSegmentBands(value) {
        this._travelPlanSegmentBands = value;
    }
    constructor(public reservationService: ReservationService) { }

    ngOnInit() {
        this.mbDecline = false;
        if (this.campus === 'wdw') {
            if (!this._travelPlanSegmentBands) {
                this.mbError = true;
                this.optedOut = false;
                this.optOutAvailable = false;
                this.pickUpMB = false;
                this.bringMB = false;
                this.guessBring = false;
            } else if (this._travelPlanSegmentBands.optOutAvailable) {
                this.mbError = false;
                this.optedOut = this._travelPlanSegmentBands.optOut;
                this.optOutAvailable = this._travelPlanSegmentBands.optOutAvailable;
                this.pickUpMB = false;
                this.bringMB = true;
                this.mbDecline = true;
                this.guessBring = false;
                this.updateRoomcheckinPayload(this._travelPlanSegmentBands);
            } else {
                this.mbError = false;
                this.optedOut = this._travelPlanSegmentBands.optOut;
                this.optOutAvailable = this._travelPlanSegmentBands.optOutAvailable;
                this.setMBDetails();
            }
        } else {
            this.mbError = false;
            this.optedOut = false;
            this.optOutAvailable = false;
            this.pickUpMB = false;
            this.bringMB = true;
            this.mbDecline = false;
            this.guessBring = false;
        }

        this.magicBandData = _.find(this.dscbDetails, function(module) {
            return module.id === constants.moduleId;
        });

    }

    setMBDetails() {
        if (this._travelPlanSegmentBands.orderStatus === constants.orderStatus.SHIP_TO_RESORT ||
            this._travelPlanSegmentBands.orderStatus === constants.orderStatus.PICKUP) {
            this.pickUpMB = true;
            this.bringMB = false;
            this.guessBring = false;
        } else if (this._travelPlanSegmentBands.orderStatus === constants.orderStatus.NO_FULFILLMENT ||
            this._travelPlanSegmentBands.orderStatus === constants.orderStatus.SHIP_TO_HOME) {
            this.pickUpMB = false;
            this.bringMB = true;
            this.guessBring = false;
        } else {
            if (this.isShipmentHoldingForResortDestination()) {
                this.pickUpMB = true;
                this.bringMB = false;
                this.guessBring = false;
            } else {
                this.pickUpMB = false;
                this.bringMB = false;
                this.guessBring = true;
            }
        }
    }

    isShipmentHoldingForResortDestination() {
        return this._travelPlanSegmentBands.holdFulfillment &&
            (this._travelPlanSegmentBands.holdFulfillmentReason === constants.holdFulfillReason.SHIP_TO_RESORT ||
                this._travelPlanSegmentBands.holdFulfillmentReason === constants.holdFulfillReason.GUEST_PROVIDED_RESORT_ADDRESS);
    }

    get heading(): string {
        return this.magicBandData.sections.title;
    }

    get headingAriaLabel(): string {
        return this.magicBandData.sections.titleAccesability;
    }

    get errorMBMsg(): string {
        return this.magicBandData.sections.mbDisplayError;
    }

    get bringYouMBMsg(): string {
        return this.magicBandData.sections.mbBringMB;
    }

    get optOutMBMsg(): string {
        return this.magicBandData.sections.mbOptOut;

    }

    get guessPickUpMBMsg(): string {
        return this.magicBandData.sections.mbGuessPickUp;
    }

    get pickUpMBMsg(): string {
        return this.magicBandData.sections.mbPickUp;
    }

    get declineMBMsg(): string {
        return this.magicBandData.sections.mbCheckboxText;

    }

    get declineMBAriaLabel(): string {
        const declineCheckBox = this.mbDecline ? this.magicBandData.descriptions[0]
        .sections.checkboxAccessibilityChecked : this.magicBandData.descriptions[0].sections.checkboxAccessibilityUnChecked;
        return declineCheckBox + this.declineMBMsg + this.magicBandData.descriptions[0].sections.footerAccesability;
    }

    onCheckBoxChanged(e) {
        this.mbDecline = e.detail.value;
        this.reservationService.roomcheckinPayload.travelPlanSegmentBands.optOut = e.detail.value;
    }

    updateRoomcheckinPayload(tpsBandDetails) {
        const customizationObj = [];
        _.forEach(tpsBandDetails.customizationSelections, (customization) => {
            const entitlementsObj = [];
            _.forEach(customization.entitlements, (entitlement) => {
                entitlementsObj.push(entitlement);
            });
            const obj = {
                'customizationSelectionId': customization.customizationSelectionId,
                'entitlements': entitlementsObj,
                'bandProductCode': customization.bandProductCode
            };
            customizationObj.push(obj);
        });
        this.reservationService.roomcheckinPayload.travelPlanSegmentBands = {
            'optOut': tpsBandDetails.optOut,
            'optOutAvailable': tpsBandDetails.optOutAvailable,
            'customizationSelections': customizationObj
        };
    }
}
