export const save = 'Save';
export const saveAlt = 'Save';
export const cancel = 'Cancel';
export const cancelAlt = 'Cancel';
export const wrapperId = 'legalWrapper';
export const footerURL = '/syndicated/content/legal' +
    '?container=legalWrapper&siteOverride=wdw&responsive=1';
export const protocol = 'https://';
export const moduleId = 'olciForm';
export const OcliMouduleId = 'informationSection';
export const hotelAccChrgModuleId = 'settlementDetails';
export const tncModuleId = 'tnc';
export const cardNameTypes = {
    'Visa': 'Visa',
    'visa': 'Visa',
    'VISA': 'Visa',
    'Master Card': 'Master Card',
    'MASTERCARD': 'Master Card',
    'MasterCard': 'Master Card',
    'Master': 'Master Card',
    'JCB': 'JCB',
    'Discover': 'Discover',
    'DISCOVER': 'Discover',
    'Diners Club': 'Diners Club',
    'DINERSCLUB': 'Diners Club',
    'DINER_CLUB': 'Diners Club',
    'American Express': 'American Express',
    'AmericanExpress': 'American Express',
    'AMERICANEXPRESS': 'American Express',
    'Amex': 'American Express',
    'AMEX': 'American Express',
    'AMX': 'American Express',
    'Disney Credit Card': 'Disney Credit Card',
    'DISNEYCREDITCARD': 'Disney Credit Card',
    'American Express Sign and Travel': 'American Express Sign and Travel',
    'AMERICANEXPRESSSIGNANDTRAVEL': 'American Express Sign and Travel',
    'Disney Rewards Card': 'Disney Rewards Card',
    'DISNEY_VISA': 'Visa'
};
export const tncType = {
    wdw: 'WDWOLCI',
    aulani: 'AulaniOLCI'
};

export const analyticsConstants = {
    pageId: 'landing',
    siteSection: 'tools/mdx/mdv/plan/portfolio',
    events: 'selfServiceInitiated',
    selfServiceType: 'OLCI',
    store: 'WDWConsumer',
    guestType: 'Guest',
    productType: 'product'
};

export const ageTypes = {
    adult: 'ADULT',
    child: 'CHILD',
    infant: 'INFANT'
};
