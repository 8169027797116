export const TNC_CONSTANTS = {
    tncMainHeading: 'TERMS AND CONDITIONS',
    tncSubHeading: 'ONLINE CHECK IN SERVICE TERMS & CONDITIONS',
    tncParaBoldPart: 'Walt Disney World Resort',
    tncPrintText: 'Print',
    tncHomeBreadcrumbName: 'Home',
    tncAulaniBreadcrumbName: 'My Aulani',
    tncWdwBreadcrumbsName: 'My Disney Experience',
    tncOLCIBreadcrumbName: 'Online Check-In'
};

export const headerURL = '/syndicated/content/header' +
    '?container=headerWrapper&siteOverride=wdw&responsive=1&showSignInSignOut=1';
export const footerURL = '/syndicated/content/footer' +
    '?container=footerWrapper&siteOverride=wdw&responsive=1';
export const protocol = 'https://';
export const wrapperId = 'footerWrapper';
export const moduleId = 'tnc';
